import { IApiResponse } from 'services/types'
import {
  IGradeResponse,
  IGradeTypesResponse
} from 'services/types/taught-disciplines'
import {
  GRADES,
  GRADE_BY_CODE,
  GRADE_FROM_GRADE_TYPE_BASE,
  GRADE_TYPE_BASE,
  GRADE_TYPE_BASE_BY_PROGRAM,
  GRADE_TYPE_BASE_BY_PROGRAM_WITH_GRADES
} from './CONSTANTS'
import { request } from 'services/base'

export const getGrades = async (): Promise<IApiResponse<IGradeResponse[]>> =>
  await request({ url: GRADES() })

export const getGradeByCode = async (
  gradeCode: string
): Promise<IApiResponse<IGradeResponse[]>> =>
  await request({ url: GRADE_BY_CODE(gradeCode) })

export const getGradeTypes = async (
  id?: string
): Promise<IApiResponse<IGradeTypesResponse[]>> =>
  await request({ url: GRADE_TYPE_BASE(id) })

export const getGradeFromGradeType = async (
  id: number,
  schoolPeriod?: number | 'current'
): Promise<IApiResponse<IGradeResponse[]>> =>
  await request({
    url: GRADE_FROM_GRADE_TYPE_BASE(id),
    params: { schoolPeriod }
  })

export const getGradeTypesByProgram = async (
  schoolPeriod?: number | 'current'
): Promise<IApiResponse<IGradeTypesResponse[]>> =>
  await request({ url: GRADE_TYPE_BASE_BY_PROGRAM(), params: { schoolPeriod } })

export const getGradeTypesByProgramWithGrades = async (): Promise<
IApiResponse<IGradeTypesResponse[]>
> => await request({ url: GRADE_TYPE_BASE_BY_PROGRAM_WITH_GRADES() })
