import useStyle from './style'
import { ExploreSection, Header } from './components'
import { CollectionsSection } from 'components/common'
import { useTranslation } from 'react-i18next'

export const HomeTeacher: React.FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <main className={classes.homeContainer}>
      <Header />

      <div className={classes.contentSections}>
        <ExploreSection />
        <CollectionsSection
          title={t('Coleções da Cloe')}
          originalCloe
          dataTestId='cloe_super_home_cloe_collections'
          prevButtonClassName='colecoesHomePrev'
          nextButtonClassName='colecoesHomeNext'
        />
      </div>
    </main>
  )
}
