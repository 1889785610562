import { useTranslation } from 'react-i18next'
import OutlinedInput from '@mui/material/OutlinedInput'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material'
import { IDisciplineResponse } from 'services/types'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
  text: {
    fontFamily: `${theme.font.fontFamily} !important`,
    lineHeight: `${theme.font.lineHeight.xs} !important`,
    fontSize: `${theme.font.fontSize.sm} !important`,
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    color: `${theme.colorBrand.medium} !important`
  },
  selector: {
    '&.MuiOutlinedInput-root': {
      color: theme.colorBrand.light,
      borderRadius: theme.borderRadius.sm,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid  ${theme.colorBrand.light}`,
        borderTop: `3px solid  ${theme.colorBrand.light}`
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colorBrand.light}`,
          borderTop: `3px solid  ${theme.colorBrand.light}`
        }
      },
      '&.Mui-focused': {
        color: theme.colorBrand.light,
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colorBrand.light}`,
          borderTop: `3px solid  ${theme.colorBrand.light}`
        }
      }
    },
    '&.selected': {
      '&.MuiOutlinedInput-root': {
        color: theme.colorBrand.medium,
        borderRadius: theme.borderRadius.sm,
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colorBrand.medium}`,
          borderTop: `3px solid  ${theme.colorBrand.medium}`
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid  ${theme.colorBrand.medium}`,
            borderTop: `3px solid  ${theme.colorBrand.medium}`
          }
        },
        '&.Mui-focused': {
          color: theme.colorBrand.medium,
          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid  ${theme.colorBrand.medium}`,
            borderTop: `3px solid  ${theme.colorBrand.medium}`
          }
        }
      }
    }
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

interface IDisciplineSelect {
  disciplines: IDisciplineResponse[]
  selectedDisciplineCode: string
  onCallback: (selectedDiscipline: IDisciplineResponse | undefined) => void
  dataTestId?: string
}

export const DisciplineSelect = ({
  disciplines,
  selectedDisciplineCode,
  onCallback,
  dataTestId
}: IDisciplineSelect) => {
  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    const discipline = disciplines.find(d => d.code === event.target.value)
    if (discipline) {
      onCallback(discipline)
    }
  }

  const theme = useTheme()
  const classes = useStyles()

  return (
    <FormControl fullWidth>
      <Select
        disabled={!disciplines}
        displayEmpty
        value={selectedDisciplineCode}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon style={{
            pointerEvents: 'none'
          }}{...props} />
        )}
        className={`${classes.selector} ${selectedDisciplineCode !== '' && 'selected'}`}
        sx={{
          overflowX: 'hidden',
          fontFamily: theme.font.fontFamily,
          lineHeight: theme.font.lineHeight.xs,
          fontSize: theme.font.fontSize.sm,
          fontWeight: theme.font.fontWeight.semibold,
          color: theme.colorBrand.medium
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem className={classes.text} disabled value={''} data-testid={dataTestId}>
          {t('Componente curricular')}
        </MenuItem>
        {
          disciplines?.map((d) => (
            <MenuItem
              key={`${d.code}`}
              className={classes.text}
              value={d.code}
            >
              {d.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
