import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: theme.borderRadius.md,
    background: theme.colors.neutral.lightBase,
    padding: theme.spacingInset.xs,
    gap: theme.spacingStack.nano,
    textDecoration: 'none !important',
    transition: 'transform 0.2s',
    width: '100%',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: theme.shadowLevels.level3,
    }
  },
  iconContainer: {
    color: theme.colors.support.colorSupport02
  },
  titleGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacingInline.nano
  },
  titleText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  description: {
    lineHeight: theme.font.lineHeight.xs + '!important',
  }
}))
