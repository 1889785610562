/* eslint-disable @typescript-eslint/prefer-includes */
import { useState, useImperativeHandle, forwardRef, createRef } from 'react'
import { Grid, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'

import { Button, TextField } from 'components/design-system'
import { SearchOutlined, CloseOutlined } from '@mui/icons-material'
import useStyles from './style'

interface ISearchProps {
  initialPattern?: string
  initialHasSubmited?: boolean
  placeholder: string
  onSubmit?: (pattern: string) => void
  onClear?: () => void
  dataTestId?: {
    submit: string
    clear: string
  }
  showSubmitButton?: boolean
  hideAssistiveText?: boolean
}

export const Search2 = forwardRef((props: ISearchProps, ref) => {
  const {
    initialPattern,
    initialHasSubmited,
    placeholder,
    onSubmit,
    onClear,
    dataTestId
  } = props
  const showSubmitButton = props.showSubmitButton ?? false

  const [pattern, setPattern] = useState(initialPattern ?? '')
  const [hasSubmited, setHasSubmited] = useState(initialHasSubmited ?? false)

  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSearching = pattern !== '' && hasSubmited

  const inputRefIconSearch = createRef<HTMLDivElement>()
  const inputRefIconClear = createRef<HTMLDivElement>()

  const clear = () => {
    setHasSubmited(false)
    setPattern('')

    if (onClear) onClear()
  }

  useImperativeHandle(ref, () => ({
    clear() {
      clear()
    }
  }))

  const SearchInnerButton = () => {
    if (isSearching) {
      return (
        <InputAdornment position='end'>
          <div ref={showSubmitButton ? '' : inputRefIconClear} data-testid={dataTestId?.clear} hidden />
          <button id='inner-input-search-button' className={classes.searchInnerButton} onClick={() => { inputRefIconClear?.current?.click(); submit() }}>
            <CloseOutlined onClick={() => clear()} />
          </button>
        </InputAdornment>
      )
    }

    return (
      <InputAdornment position='end'>
        <div ref={showSubmitButton ? '' : inputRefIconSearch} data-testid={dataTestId?.submit} hidden />
        <button id='inner-input-search-button' className={classes.searchInnerButton} onClick={() => { inputRefIconSearch?.current?.click(); submit() }}>
          <SearchIcon />
        </button>
      </InputAdornment>
    )
  }
  const submit = () => {
    if (pattern === '' || (pattern && hasSubmited)) {
      clear()
      return
    }

    if (pattern.length <= 2) {
      return
    }

    setHasSubmited(true)
    if (onSubmit) {
      onSubmit(pattern)
    }
  }

  const handleSelectText = () => {
    document.getElementById("search-input")?.addEventListener("click", () => {
      const searchBar = document.getElementById("search-input") as HTMLInputElement
      searchBar.focus()
      searchBar.select()
    });
  }

  return (
    <Grid
      container
      component='form'
      display='flex'
      justifyContent='flex-start'
      spacing={2}
      sx={{ flex: 1, display: 'block' }}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        submit()
      }}
    >
      <Grid item>
        <TextField
          className={classes.searchInput}
          fullWidth
          id='search-input'
          variant='filled'
          label={placeholder}
          value={pattern}
          onChange={e => {
            setHasSubmited(false)
            setPattern(e.target.value)
          }}
          endAdornment={<SearchInnerButton />}
          onClick={handleSelectText}
        />
      </Grid>
      {showSubmitButton && (
        <Grid item lg={3} xs={2}>
          <Button
            type='submit'
            id='search-button'
            className={classes.searchButton}
            startIcon={hasSubmited ? <CloseOutlined /> : <SearchOutlined />}
            variant='outlined'
            data-testid={
              hasSubmited && dataTestId ? dataTestId.clear : dataTestId?.submit
            }
          >
            {!isMobile && !hasSubmited && t('Localizar')}
            {!isMobile && hasSubmited && t('Limpar')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
})
