import React, { useState } from 'react'
import { Box, Dialog, Modal, useMediaQuery, useTheme } from '@mui/material'
import { Button, Tag, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { IClassEntity, IClassesTaught } from 'services/types/taught-disciplines'
import { CONTENT_UNIT_TEACHER } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import cloeAvatar from 'assets/cloe_avatar_arms_lifted.webp'

interface IModalProgramCloe {
  classesTaught: IClassesTaught | null
  isOpen: boolean
  contentId: number
  onClose: () => void
}

enum ModalIndexEnum {
  TAGS = 0,
  CONFIRM = 1,
}

export const ModalProgramCloe = ({ classesTaught, isOpen, contentId, onClose }: IModalProgramCloe) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()
  const history = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedClass, setSelectedClass] = useState<IClassEntity>()
  const [modalIndex, setModalIndex] = useState(ModalIndexEnum.TAGS)

  const handleSelectClass = (newClass: IClassEntity) => {
    setSelectedClass(newClass)
  }

  const handleConfirmModal = (newClass: IClassEntity | undefined) => {
    if (classesTaught) {
      history.push(CONTENT_UNIT_TEACHER(classesTaught.segmentCode, `${classesTaught.gradeId}`, `${newClass?.id}`, `${contentId}`))
    }
  }

  const handleChangeModal = () => {
    setModalIndex(
      prev =>
        prev === ModalIndexEnum.TAGS
          ? ModalIndexEnum.CONFIRM
          : ModalIndexEnum.TAGS
    )
  }

  const handleRedirect = () => {
    history.push('/taught-disciplines')
  }

  const TagsContainer = () => {
    return (
      <>
        <Box className={classes.modalContent}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            {t('Selecione uma turma para acessar a unidade de conteúdo:')}
          </Text2>
          <Box className={classes.modalItems}>
            <Text2
              fontSize='md'
              fontWeight='medium'
              lineHeight='sm'
              mobile='md'
              customColor={theme.colorBrand.medium}
            >
              <SchoolOutlinedIcon /> {classesTaught?.gradeName}
            </Text2>
            <Box className={classes.tagsContainer}>
              {classesTaught?.classes?.map(item => {
                return (
                  <Tag
                    dataTestid='program_cloe_content_unit_select_class'
                    key={item?.id}
                    className={classes.classTag}
                    color={item?.id === selectedClass?.id ? theme.colors.neutral.lightBase : undefined}
                    background={item?.id === selectedClass?.id ? theme.colorBrand.medium : undefined}
                    onClick={() => {
                      handleSelectClass(item)
                    }}
                  >
                    {t(item.name)}
                  </Tag>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            variant={'outlined'}
            data-testid='program_cloe_content_unit_select_class_cancel'
            onClick={onClose}
          >
            {t('Cancelar')}
          </Button>
          <Button
            variant={'primary'}
            data-testid='program_cloe_content_unit_select_continue'
            disabled={!selectedClass}
            onClick={() => {
              handleChangeModal()
            }}
          >
            {t('Confirmar')}
          </Button>
        </Box>
      </>
    )
  }

  const Confirm = () => {
    return (
      <>
        <Box className={classes.confirmModalContent}>
          <img src={cloeAvatar} width={200} />
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            {t('Você selecionou a turma')}
          </Text2>
          <Text2
            fontSize='md'
            fontWeight='medium'
            lineHeight='sm'
            mobile='md'
            customColor={theme.colorBrand.medium}
          >
            <SchoolOutlinedIcon /> {selectedClass?.name}
          </Text2>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            variant={'outlined'}
            data-testid='program_cloe_content_unit_classconfig_select_cancel'
            onClick={() => {
              handleChangeModal()
            }}
          >
            {t('Voltar')}
          </Button>
          <Button
            variant={'primary'}
            data-testid='program_cloe_content_unit_classconfig_select_configclass'
            onClick={() => {
              handleConfirmModal(selectedClass)
            }}
          >
            {t('Vamos lá!')}
          </Button>
        </Box>
      </>
    )
  }

  const Content = () => {
    return (
      <>
        <Box className={classes.modalHeader}>
          <Button
            startIcon={<CloseIcon />}
            variant={'ghost'}
            data-testid={modalIndex === ModalIndexEnum.TAGS ? 'program_cloe_content_unit_select_class_close' : 'program_cloe_content_unit_classconfig_select_close'}
            className={classes.closeButton}
            onClick={onClose}
          >
            {t('Fechar')}
          </Button>
        </Box>
        {modalIndex === ModalIndexEnum.TAGS ? <TagsContainer /> : <Confirm />}
      </>
    )
  }

  const EmptyContent = () => {
    return (
      <>
        <Box className={classes.modalHeader}>
          <Button
            startIcon={<CloseIcon />}
            variant={'ghost'}
            data-testid='program_cloe_content_unit_noclass_select_close'
            className={classes.closeButton}
            onClick={onClose}
          >
            {t('Fechar')}
          </Button>
        </Box>
        <Box className={classes.modalContent}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            {t('Selecione uma turma para acessar a unidade de conteúdo:')}
          </Text2>
          <Box className={classes.emptyState}>
            <Text2
              fontSize='md'
              fontWeight='medium'
              lineHeight='sm'
              mobile='md'
              customColor={theme.colorBrand.medium}
            >
              {t('Você ainda não possui turmas adicionadas nesse ano/série em 2024')}
            </Text2>
            <Text2
              fontSize='sm'
              fontWeight='medium'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colors.neutral.dark10}
            >
              {t('Escolha as turmas e os componentes curriculares em que leciona para prosseguir.')}
            </Text2>
          </Box>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            variant={'outlined'}
            data-testid='program_cloe_content_unit_noclass_select_cancel'
            onClick={onClose}
          >
            {t('Voltar')}
          </Button>
          <Button
            variant={'primary'}
            data-testid='program_cloe_content_unit_noclass_select_configclass'
            onClick={() => {
              handleRedirect()
            }}
          >
            {t('Adicionar turmas')}
          </Button>
        </Box>
      </>
    )
  }

  return (
    isMobile
      ? (
        <Dialog
          fullScreen
          open={isOpen}
          onClose={onClose}
          aria-labelledby='fullscreen-dialog-title'
        >
          {classesTaught?.classes?.length ? <Content /> : <EmptyContent />}
        </Dialog>
      )
      : (
        <Modal onClose={onClose} open={isOpen}>
          <Box className={classes.modalContainer}>
            {classesTaught?.classes?.length ? <Content /> : <EmptyContent />}
          </Box>
        </Modal>
      )
  )
}
