import { IActivityResponse } from 'services/types'
import { Intro, Legend, SearchContainer } from '../../style'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { Grid, useTheme } from '@mui/material'
import { Search } from 'components/design-system'
import ContentLink from 'components/common/ContentLink'
import { ContentTypeEnum } from 'services/types/content'
import { EmptyStateComponent, StatusSelector } from 'components/common'
import NaturalCompare from 'string-natural-compare'
import useStyle from './style'
import { useState } from 'react'

interface IFinished {
  activitiesCompleted: IActivityResponse[]
  isProjectViewMode: boolean
  isCloeExpand: boolean
  handleSearch: (pattern: string) => void
  handleClear: () => void
  searchRef: React.RefObject<any>
  searchPhrase: string
  contentId: string
  gradeCode: string
  gradeTypeCode: string
  classId: string
}

type orderSortEnum = 'ASC' | 'DESC'
type usedFilterEnum = 'ALL' | 'USED' | 'N_USED'

interface IActivityViewOptions {
  sort: orderSortEnum
  filter: usedFilterEnum
}

const DEFAULT_ACTIVITY_VIEW_OPTION: IActivityViewOptions = {
  sort: 'ASC',
  filter: 'ALL'
}

export const Finished = ({
  activitiesCompleted,
  isProjectViewMode,
  isCloeExpand,
  handleSearch,
  handleClear,
  searchRef,
  searchPhrase,
  contentId,
  gradeCode,
  gradeTypeCode,
  classId
}: IFinished) => {
  const { t, Trans } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const theme = useTheme()

  const classes = useStyle()

  const [activityViewOptions, setActivityViewOptions] = useState<IActivityViewOptions>(DEFAULT_ACTIVITY_VIEW_OPTION)

  const handleViewOptions = (activities: IActivityResponse[], sort: orderSortEnum, filter: usedFilterEnum) => {
    const activitiesSorted = activities?.map(cur => {
      // making the title just one to put in alphabetically order
      cur.title = cur.title ?? cur.activity_name
      cur.title = cur.title.trim()
      return cur
    }).sort((a, b) => {
      if (sort === 'ASC') {
        return NaturalCompare(a.title, b.title, { caseInsensitive: true })
      }
      return NaturalCompare(b.title, a.title, { caseInsensitive: true })
    })

    return activitiesSorted
  }

  const orderStatusOptions = {
    ASC: {
      title: t('Nome - crescente (A - Z)')
    },
    DESC: {
      title: t('Nome - decrescente (Z - A)')
    }
  }

  const activitiesProcessed = handleViewOptions(activitiesCompleted, activityViewOptions.sort, activityViewOptions.filter)

  return (
    <>
      <Intro>
        {t('Atividades finalizadas')}
        <Grid item sx={{ pt: 2 }}>
          <Legend>
            {t('As atividades a seguir já foram corrigidas e devolvidas para a turma.')}
          </Legend>
        </Grid>
      </Intro>
      <SearchContainer container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Search
            placeholder='Localizar na página'
            onSubmit={handleSearch}
            onClear={handleClear}
            dataTestId={{
              clear: '',
              submit: ''
            }}
            ref={searchRef}
            showSubmitButton={false}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <StatusSelector
            onChange={async sortOption => {
              setActivityViewOptions(old => ({ ...old, sort: sortOption }))
            }}
            statusOptions={orderStatusOptions}
            customContainerClassName={classes.dropdownContainer}
          />
        </Grid>
      </SearchContainer>
      {
        activitiesProcessed.length === 0
          ? <EmptyStateComponent>
            <Grid container flexDirection='column' alignItems='center' sx={{ fontSize: theme.font.fontSize.md, color: theme.colors.neutral.dark30 }}>
              <span style={{ display: 'inline' }}>
                <Trans i18nKey={`Poxa, parece que o termo ${searchPhrase} não foi encontrado.`}>
                  Poxa, parece que o termo <b style={{ color: theme.colorBrand.medium }}>{searchPhrase}</b> não foi encontrado.
                </Trans>
              </span>
              <Trans i18nKey='Você pode tentar outros termos ou verificar se houve algum erro de digitação.'>
                Você pode tentar outros termos ou verificar se houve algum erro de digitação.
              </Trans>
            </Grid>
          </EmptyStateComponent>
          : <Grid container>
            <Grid item gap={theme.spacingInline.xxs} display='flex' xs={12} sx={{
              flexWrap: 'wrap',
              background: theme.colors.neutral.lightBase,
              [theme.breakpoints.down('sm')]: {
                justifyItems: 'center'
              }
            }}>
              {
                activitiesProcessed.map((e: IActivityResponse, i) =>
                  <Grid sx={{
                    maxWidth: '284px',
                    // minWidth: '284px',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '100%',
                      minWidth: '100%'
                    }
                  }} key={i}>
                    <ContentLink
                      id={contentId?.toString()}
                      activityId={e.id?.toString()}
                      contentId={contentId}
                      gradeCode={gradeCode}
                      gradeTypeCode={gradeTypeCode}
                      classId={classId}
                      title={e.title ?? e.activity_name}
                      type={e.type === 'guide' ? ContentTypeEnum.guide : ContentTypeEnum.activity}
                      momentType={e.moment_type}
                      statusReleased={true}
                      hasNotification={e.hasNotification}
                      useType={e.use_type}
                      suggestedApplicationType={e.suggested_application_type}
                      dataTestid='activities_select_finished_activity'
                      isProjectViewMode={isProjectViewMode}
                      useCloeExpandTranslation={isCloeExpand}
                      quantityQuestions={e.questions.length}
                    />
                  </Grid>)
              }
            </Grid>
          </Grid>
      }
    </>
  )
}
