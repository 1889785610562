import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LibrarySearchSkeleton, LibrarySearchError, LibrarySearchEmpty } from './LibrarySearchStates'
import { ICollectionResponse, ISearchResponse } from 'services/types/library'
import { Pagination, CheckBox, Text } from 'components/design-system'
import { CollectionCard, SimpleCollapse } from 'components/common'
import { IDisciplineResponse } from 'services/types'
import { useEffect, useState } from 'react'
import { searchCollectionLibrary } from 'services/library'
import { LibrarySearchFilters } from './LibrarySearchFilters'
import { useAtom } from 'jotai'
import { updateSearchRequestAtom } from '../atomStore'
import { IGradeTypesResponse } from 'services/types/taught-disciplines'
import useStyles from '../styles'
import { RenderWhenCondition } from 'utils/wrappers'
import { Close } from '@mui/icons-material'

interface IProps {
  pageSize: number
  searchQuery: string
  filters: {
    gradeTypes: IGradeTypesResponse[]
    taughtDisciplines: IDisciplineResponse[]
  }
  selectMode?: boolean
  isFilterSelected?: boolean
  setIsFilterSelected?: () => void
}

export const LibrarySearchCollections = (props: IProps) => {
  // props
  const {
    searchQuery,
    pageSize,
    filters,
    isFilterSelected,
    setIsFilterSelected
  } = props
  // states
  const [state, setState] = useState<'loading' | 'success' | 'error' | 'notFound'>('loading')
  const [searchResponse, setSearchResponse] = useState<ISearchResponse<ICollectionResponse>>()
  const [searchRequest, setSearchRequest] = useAtom(updateSearchRequestAtom)
  // translation
  const { t } = useTranslation()
  // mediaQuery
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // styles
  const classes = useStyles({ isMobile })

  const fetchSearch = async () => {
    const request = { ...searchRequest }
    if (!searchRequest.disciplines.length) {
      request.disciplines = filters.taughtDisciplines.map(t => t.id) ?? []
    }
    if (!searchRequest.gradeTypes.length) {
      request.gradeTypes = filters.gradeTypes.map(g => g.id) ?? []
    }
    const response = await searchCollectionLibrary(request)
    if (response.success) {
      setSearchResponse(response.data)
      if (response.data?.results?.length) {
        setState('success')
      } else {
        setState('notFound')
      }
    } else {
      setState('error')
    }
  }

  useEffect(() => {
    const hasFilterDisciplines = !!filters.taughtDisciplines?.length
    const hasFilterGradeTypes = !!filters.gradeTypes?.length
    if (searchRequest.term && hasFilterDisciplines && hasFilterGradeTypes) {
      setState('loading')
      fetchSearch()
    }
  }, [searchRequest, filters])

  useEffect(() => {
    setSearchRequest({
      type: 'UPDATE',
      payload: {
        ...searchRequest,
        term: searchQuery,
        pageSize,
        pageNumber: 1
      }
    })
  }, [searchQuery])

  const onCheckFilter = (filter: 'disciplines' | 'gradeTypes' | 'types', value: number | string) => {
    const checked = !!searchRequest[filter]?.find(rd => rd === value)
    const newFilter = checked
      ? searchRequest[filter]?.filter(rd => rd !== value)
      : searchRequest[filter]?.concat(value)
    if (newFilter) {
      setSearchRequest({
        type: 'UPDATE',
        payload: {
          ...searchRequest,
          pageNumber: 1,
          [filter]: [...newFilter]
        }
      })
    }
  }

  const render = {
    loading: <LibrarySearchSkeleton mockCount={pageSize} />,
    success: <>
      <Grid item xs={12} display='flex' mt={2} gap={2} flexWrap='wrap' justifyContent={isMobile ? 'center' : 'left'}>
        {searchResponse?.results?.map((collection, index) => {
          return (
            <CollectionCard
              key={index}
              collection={collection}
              queryParams={{ librarySearch: searchQuery }}
            />)
        })
        }
      </Grid>
      <Grid item xs={12} mt={2} display='flex' justifyContent='center'>
        <Pagination
          count={searchResponse?.totalPages}
          defaultPage={searchRequest.pageNumber}
          boundaryCount={1}
          siblingCount={1}
          variant='outlined'
          onChange={(e, value) => setSearchRequest({
            type: 'UPDATE',
            payload: {
              ...searchRequest,
              pageNumber: value
            }
          })}
        />
      </Grid>
    </>,
    error: <LibrarySearchError />,
    notFound: <LibrarySearchEmpty />
  }

  return (
    <Grid container spacing={3} alignItems='flex-start'>
      {/* Filter */}
      <Grid container item xs={3} gap={3} className={`${classes.libraryFilterContainer} ${isFilterSelected && 'selected'}`}>
        <RenderWhenCondition condition={isMobile}>
          <Grid item xs={12} display='flex' justifyContent='space-between'>
            <Text size='small' type='heading' color='medium'>
              {t('Filtrar recursos')}
            </Text>
            <div onClick={setIsFilterSelected}>
              <Close />
            </div>
          </Grid>
        </RenderWhenCondition>
        <Grid item xs={12}>
          <SimpleCollapse title={t('Componente curricular')} isInitialExpanded>
            {filters.taughtDisciplines.map((d, i) => (
              <CheckBox
                key={i}
                checked={!!searchRequest.disciplines.find(rd => rd === d.id)}
                onClick={() => onCheckFilter('disciplines', d.id)}
                value={d.id}
                size='small'
              >
                {d.name}
              </CheckBox>
            ))}
          </SimpleCollapse>
        </Grid>
        <Grid item xs={12}>
          <SimpleCollapse title={t('Segmento')} isInitialExpanded>
            {filters.gradeTypes.map((gr, i) => (
              <CheckBox
                key={i}
                checked={!!searchRequest.gradeTypes?.find(rd => rd === gr.id)}
                onClick={() => onCheckFilter('gradeTypes', Number(gr.id))}
                value={gr.id}
                size='small'
              >
                {gr.name}
              </CheckBox>
            ))}
          </SimpleCollapse>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={9} gap={2}>
        <LibrarySearchFilters
          filters={filters}
          request={searchRequest}
          onClear={onCheckFilter}
          page='collections'
          onClearAll={() => setSearchRequest({
            type: 'UPDATE',
            payload: {
              ...searchRequest,
              pageNumber: 1,
              gradeTypes: [],
              disciplines: [],
              types: []
            }
          })}
        />
        {render[state]}
      </Grid>
    </Grid>
  )
}
