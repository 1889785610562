import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  searchButton: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.borderRadius.circular,
      padding: '16px !important',
      minWidth: 'auto  !important',
      '& span': {
        margin: '0 !important'
      }
    }
  },
  searchInput: {
    minHeight: '54px',
    width: '100%',
    cursor: 'text',
    '& p': {
      minHeight: 'unset'
    },
    '& label': {
      color: theme.colors.neutral.dark40 + '!important',
      paddingLeft: theme.spacingInline.quarck,
      paddingRight: theme.spacingInline.md
    },
    '& .MuiInputBase-root': {
      background: theme.colors.neutral.lightBase + '!important',
      border: `2px solid ${theme.colors.support.colorSupport02} !important`,
      borderRadius: theme.borderRadius.pill + '!important',
      '& input': {
        zIndex: 0
      }
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 'inherit',
    }
  },
  searchInnerButton: {
    background: 'none',
    color: theme.colors.support.colorSupport02,
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    height: 24
  },
  searchTextInfo: {
    marginBottom: theme.spacingStack.xxxs,
    color: theme.colors.neutral.dark20,
    fontWeight: theme.font.fontWeight.medium,

    '& span': {
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      color: `${theme.colorBrand.dark} !important`
    }
  }
}))
