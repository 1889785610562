import useStyle from './style'
import { Tag, Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { IResourceTypes, resourceTypesNames } from 'services/types'
import { NavLink } from 'react-router-dom'
import { LIBRARY_CONTENT_DETAIL } from 'navigation/CONSTANTS'
import { ResourceThumb } from '../ResourceThumb'
import { BookmarkBorderOutlined, Close, ShareOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { selectedArticlesAtom, selectedResourcesAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import { IArticleResponse, IResourceResponse } from 'services/types/library'
import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useUpdateAtom } from 'jotai/utils'
import { selectedArticlesToSaveAtom, selectedResourcesToSaveAtom } from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentSave/atomStore'
import Analytics from 'utils/analytics'
import { useStore } from 'store'
import { selectedLibraryTabAtom } from '../CloeMaterialSearch/atomStore'
import { TAB_INDEX_ENUM } from '../CloeMaterialSearch'

export interface ILibraryContentCardProps {
  resource?: IResourceResponse
  article?: IArticleResponse
  isSelected?: boolean
  selectMode?: boolean
  reviewMode?: boolean
  onClick?: () => void
  selectedFor?: 'share' | 'save'
  page: 'class-plan' | 'library' | 'collection'
  queryParams?: { [key: string]: string }
}

export const LibraryContentCard = (props: ILibraryContentCardProps) => {
  const { resource, article, isSelected, selectedFor, selectMode = false, onClick = () => { }, reviewMode = false, page, queryParams } = props
  const { t } = useTranslation()
  const classes = useStyle()
  // atoms
  const setResourcesSelected = useUpdateAtom(selectedResourcesAtom)
  const setArticlesSelected = useUpdateAtom(selectedArticlesAtom)

  const setResourcesSelectedToSave = useUpdateAtom(selectedResourcesToSaveAtom)
  const setArticlesSelectedToSave = useUpdateAtom(selectedArticlesToSaveAtom)

  const { profile, subscription } = useStore()
  const [tabIndex] = useAtom(selectedLibraryTabAtom)

  // props merged
  const id = resource?.resourceId ?? article?.articleId ?? ''
  const title = resource?.title ?? article?.title ?? ''
  const comment = resource?.metadata.comment ?? article?.comment ?? ''
  const thumbnail = resource?.thumbnail ?? article?.thumbnail ?? ''
  const originalCloe = resource?.madeByCloe ?? true
  const type = resource?.type ?? 'article'
  const isArticle = !!article?.articleId

  const dataTestIds = {
    library: {
      [TAB_INDEX_ENUM.RECURSOS]: {
        openCardEvent: 'cloe_super_library_resource_open',
        errorOpenCardEvent: 'cloe_super_library_resource_error_open',
        share: 'cloe_super_library_resource_select_share',
        save: 'cloe_super_library_resource_select_save',
        open: 'cloe_super_library_resource_select_open',
      },
      [TAB_INDEX_ENUM.CAPITULOS]: {
        openCardEvent: 'cloe_super_library_chapter_open',
        errorOpenCardEvent: 'cloe_super_library_chapter_error_open',
        share: 'cloe_super_library_chapter_select_share',
        save: 'cloe_super_library_chapter_select_save',
        open: 'cloe_super_library_chapter_select_open',
      },
      [TAB_INDEX_ENUM.COLECOES]: {
        openCardEvent: 'cloe_super_library_collection_open',
        errorOpenCardEvent: 'cloe_super_library_collection_error_open',
        share: 'cloe_super_library_collection_select_share',
        save: 'cloe_super_library_collection_select_save',
        open: 'cloe_super_library_collection_select_open',
      }
    },
    'class-plan': {
      [TAB_INDEX_ENUM.RECURSOS]: {
        openCardEvent: 'cloe_super_class_plan_resource_open',
        errorOpenCardEvent: 'cloe_super_class_plan_resource_error_open',
        share: 'cloe_super_class_plan_resource_select_share',
        save: 'cloe_super_class_plan_resource_select_save',
        open: 'cloe_super_class_plan_resource_select_open',
      },
      [TAB_INDEX_ENUM.CAPITULOS]: {
        openCardEvent: 'cloe_super_class_plan_chapter_open',
        errorOpenCardEvent: 'cloe_super_class_plan_chapter_error_open',
        share: 'cloe_super_class_plan_chapter_select_share',
        save: 'cloe_super_class_plan_chapter_select_save',
        open: 'cloe_super_class_plan_chapter_select_open',
      },
      [TAB_INDEX_ENUM.COLECOES]: {
        openCardEvent: 'cloe_super_class_plan_collection_open',
        errorOpenCardEvent: 'cloe_super_class_plan_collection_error_open',
        share: 'cloe_super_class_plan_collection_select_share',
        save: 'cloe_super_class_plan_collection_select_save',
        open: 'cloe_super_class_plan_collection_select_open',
      }
    },
    collection: {
      [TAB_INDEX_ENUM.RECURSOS]: {
        openCardEvent: 'cloe_super_collection_open',
        errorOpenCardEvent: 'cloe_super_collection_error_open',
        share: 'cloe_super_collection_open_select_share',
        save: 'cloe_super_collection_open_select_save',
        open: 'cloe_super_collection_open_select_open',
      },
      [TAB_INDEX_ENUM.CAPITULOS]: {
        openCardEvent: 'cloe_super_collection_open',
        errorOpenCardEvent: 'cloe_super_collection_error_open',
        share: 'cloe_super_collection_open_select_share',
        save: 'cloe_super_collection_open_select_save',
        open: 'cloe_super_collection_open_select_open',
      },
      [TAB_INDEX_ENUM.COLECOES]: {
        openCardEvent: 'cloe_super_collection_open',
        errorOpenCardEvent: 'cloe_super_collection_error_open',
        share: 'cloe_super_collection_open_select_share',
        save: 'cloe_super_collection_open_select_save',
        open: 'cloe_super_collection_open_select_open',
      }
    }
  }

  const dataTest = dataTestIds[page][tabIndex]

  const onClickSelectShare = (e?: React.MouseEvent<any, MouseEvent>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (isArticle && article) {
      setArticlesSelected({ type: 'ADD', payload: article, openMyCloe: !selectMode })
    } else if (resource) {
      setResourcesSelected({ type: 'ADD', payload: resource, openMyCloe: !selectMode })
    }
  }

  const onClickSelectSave = (e?: React.MouseEvent<any, MouseEvent>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (isArticle && article) {
      setArticlesSelectedToSave({ type: 'ADD', payload: article, openMyCloe: !selectMode })
    } else if (resource) {
      setResourcesSelectedToSave({ type: 'ADD', payload: resource, openMyCloe: !selectMode })
    }
  }

  const onClickRemove = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (isArticle && article) {
      if (selectedFor === 'share') setArticlesSelected({ type: 'REMOVE', payload: article })
      else setArticlesSelectedToSave({ type: 'REMOVE', payload: article })
    } else if (resource) {
      if (selectedFor === 'share') setResourcesSelected({ type: 'REMOVE', payload: resource })
      else setResourcesSelectedToSave({ type: 'REMOVE', payload: resource })
    }
  }

  const Header = () => {
    if (reviewMode) return <></>

    if (selectMode) {
      if (isSelected) {
        return (
          <div className={classes.selectModeHeader} onClick={onClickRemove}>
            {t('Remover')} <Close />
          </div>
        )
      }
      return (
        <div className={classes.selectModeHeader} onClick={onClickSelectShare}>
          {t('Selecionar')} <AddIcon />
        </div>
      )
    }

    if (isSelected) {
      return (
        <div className={classes.headerRemove} onClick={onClickRemove} data-testid='my_cloe_save_remove_select'>
          {t('Remover')} <Close />
        </div>
      )
    }

    return (
      <div className={classes.headerOption}>
        <IconButton aria-label='share' size='small' title={t('Compartilhar')} onClick={onClickSelectShare} data-testid={dataTest.share}>
          <ShareOutlined />
        </IconButton>
        <IconButton aria-label='save' size='small' title={t('Salvar')} onClick={onClickSelectSave} data-testid={dataTest.save}>
          <BookmarkBorderOutlined />
        </IconButton>
      </div>
    )
  }

  let queryParamsString = '?'
  if (queryParams) {
    Object.keys(queryParams)?.forEach(q => {
      queryParamsString += `${q}=${queryParams[q]}&`
    })
  }

  return (
    <NavLink
      to={LIBRARY_CONTENT_DETAIL(id.toString(), type, queryParamsString)}
      className={classes.cardLink}
      target={selectMode || reviewMode ? '_blank' : '_self'}
      rel='noopener'
      data-testid={dataTest.open}
      onClick={e => {
        if (isSelected) {
          e.stopPropagation()
          e.preventDefault()
          // onClickRemove()
        }

        Analytics.recordEventClick({
          name: dataTest.openCardEvent,
          attributes: {
            ...profile?.analytics,
            ...subscription?.analytics,
            content: props
          }
        })

        onClick()
      }}>
      <div className={classes.cardContainer} style={{ background: originalCloe ? 'red !important' : '' }}>
        <div className={classes.headerContainer}>
          <ResourceThumb
            thumbnail={thumbnail}
            type={type}
            size='small'
            originalCloe={originalCloe}
          />
          <div><Header /></div>
        </div>
        <div className={classes.title}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={classes.subTitle}>
          <Text size='small' type='body'>
            <div dangerouslySetInnerHTML={{ __html: comment }} />
          </Text>
        </div>
        <div className={classes.cardFooter}>
          <Tag className={classes.tag}>
            {type in resourceTypesNames ? t(resourceTypesNames[type as IResourceTypes]) : t('article')}
          </Tag>
        </div>
      </div>
    </NavLink>
  )
}

export default LibraryContentCard
