import useStyle from './style'
import { Text2, Icon } from 'components/design-system'
import { ReactComponent as StockMediaIcon } from 'assets/stock-media.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloeIcon } from 'assets/cloe-simplified-icon.svg'
import { Link } from 'react-router-dom'
import { COLLECTIONS } from 'navigation/CONSTANTS'
import { ResourceThumb } from '../ResourceThumb'
import { ICollectionResponse } from 'services/types/library'

export interface IMedia {
  url?: string | null
}

export interface CollectionCardProps {
  collection: ICollectionResponse
  selectMode?: boolean
  onSelect?: (item: ICollectionResponse) => void
  isSelected?: boolean
  dataTestId?: string
  queryParams?: { [key: string]: string }
}

export const CollectionCard = (props: CollectionCardProps) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const { collection, selectMode = false, onSelect, isSelected = false, dataTestId, queryParams } = props
  const { id, title, author } = collection.collection
  const { thumbnails } = collection
  const countItems = collection.articles.length + collection.resources.length
  const originalCloe = author.type === 'Admin'

  return (
    <Link
      to={COLLECTIONS(id, { madeByCloe: originalCloe ? 'true' : 'false', ...queryParams })}
      className={`${classes.cardContainer} ${isSelected ? 'isSelected' : ''}`}
      target={selectMode ? '_blank' : '_self'}
      rel='noopener'
      onClick={(e) => {
        if (selectMode) {
          e.stopPropagation()
          e.preventDefault()
        }
        if (onSelect) {
          onSelect(collection)
        }
      }}
      data-testid={dataTestId}
    >
      <div className={classes.containerBody}>
        <div className={classes.containerThumbnails}>
          {
            thumbnails?.length
              ? thumbnails.filter((t, i) => i < 3).map(thumb =>
                <ResourceThumb
                  thumbnail={thumb}
                  type='gallery'
                  size='small'
                  originalCloe={false}
                />)
              : <ResourceThumb
                type='article'
                size='small'
                originalCloe={false}
              />
          }
        </div>
        <div className={classes.titleGroup}>
          <Text2 fontSize='lg' fontWeight='semibold' colorbrand='dark'>
            {title}
          </Text2>
        </div>
      </div>
      <div className={classes.cardFooter}>
        <div className={classes.mediaCounter}>
          <Icon titleAccess={t('Biblioteca')} size='small'>
            <StockMediaIcon />
          </Icon>
          <Text2 fontSize='xxs' fontWeight='medium' colorbrand='medium'>
            {countItems}
          </Text2>
        </div>
        {
          originalCloe && (
            <div className={classes.svgIconContainer} >
              <CloeIcon />
            </div>
          )
        }
      </div>
    </Link>
  )
}
