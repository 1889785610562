import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
// import LibraryWormBackground from 'assets/cloe-worm-horizontal.svg'

export default makeStyles((theme: Theme) => createStyles({
  libraryContainer: ({ useWorm }: { useWorm: boolean }) => ({
    // backgroundImage: useWorm ? `url(${LibraryWormBackground})` : '',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    overflowX: 'hidden',
    overflowY: 'clip',
    minHeight: '100%',
    paddingBottom: '64px !important',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacingInline.xxxs,
      paddingBottom: '60px',
      backgroundImage: 'none'
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: '66px !important'
    }
  }),
  skelletonRounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  },
  libraryTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start !important',
      gap: theme.spacingStack.xxs,
      '& button': {
        textTransform: 'unset !important'
      }
    },
    borderBottom: '1px solid',
    borderBottomColor: theme.colors.neutral.light10
  },
  libraryTab: {
    maxWidth: '200px !important',
    display: 'flex !important',
    justifyContent: 'flex-start !important'
  },
  contentSections: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: `${theme.spacingInset.sm} 0`,
    gap: theme.spacingStack.xxs,
    width: '100%'
  },
  libraryFilterTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.xs,
    border: `2px solid ${theme.colors.neutral.light10}`
  },
  libraryFilterTagClose: {
    borderRadius: theme.borderRadius.circular,
    background: theme.colors.neutral.light40,
    color: theme.colors.neutral.lightBase,
    fontSize: '16px !important',
    cursor: 'pointer'
  },
  libraryClearFilterTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    border: 'none',
    background: theme.colors.neutral.lightBase,
    color: theme.colorBrand.medium,
    cursor: 'pointer',

    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px', /* 133.333% */
    letterSpacing: '0.12px',

    '& svg': {
      fontSize: '16px !important',
      color: theme.colors.neutral.darkBase
    }
  }
}))
