import React, { Fragment, ReactNode } from 'react'
import { renderChildrenWithProps } from 'utils/wrappers'
import { useStore } from 'store'

interface IFeatureToggleProps {
  [propName: string]: any
}

export const CloeSuperToggle: React.FC<IFeatureToggleProps> = ({ children, ...props }) => {
  const { profile, schoolPeriod } = useStore()

  const hasCloeSuper = profile?.school?.has_cloe_super
  const hasApprovedCloeSuper = profile?.school?.has_approved_cloe_super
  const hasCurrentSchoolPeriod = !!schoolPeriod?.current

  const toggledChildren = React.Children.map(children, (child: any) => React.cloneElement(child, {
    featureEnabled: hasCloeSuper && hasApprovedCloeSuper && hasCurrentSchoolPeriod,
    props
  }))

  return (
    <React.Fragment>
      {toggledChildren}
    </React.Fragment>
  )
}

interface ICloeSuperToggleComponent {
  children: ReactNode
  featureEnabled?: boolean
  props?: {
    [propName: string]: any
  }
}

export const Enabled: React.FC<ICloeSuperToggleComponent> = ({ children, featureEnabled, props }) => {
  if (!featureEnabled) {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<ICloeSuperToggleComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export const Disabled: React.FC<ICloeSuperToggleComponent> = ({ children, featureEnabled, props }) => {
  if (featureEnabled) {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<ICloeSuperToggleComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export default CloeSuperToggle
