import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '24px',
    gap: '8px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    }
  },
  descriptionDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  descriptionOriginalCloe: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  typeTag: {
    background: theme.colors.support.colorSupport02,
    color: theme.colors.neutral.lightBase
  },
  detailsTagLink: {
    textDecoration: 'none',
    '& > label': {
      cursor: 'pointer'
    }
  },
  detailsTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.xs,
    border: `2px solid ${theme.colorBrand.lightest}`,
    color: theme.colorBrand.medium,
    textTransform: 'capitalize',

    '&.segment': {
      background: theme.colorBrand.lightest,
    }
  },
  disciplineTag: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    flexWrap: 'wrap',
    '& > button, a': {
      minWidth: '167px',
      flexGrow: 1,
      '& > svg': {
        marginLeft: 14
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      position: 'absolute',
      bottom: '0',
      left: '0',
      margin: '0',
      padding: `${theme.spacingInset.xs}`,
      backgroundColor: '#F7F7F7',
      width: '100%',
      justifyContent: 'center !important'
    }
  },
  scheduleContent: {
    background: 'rgba(212, 204, 226, 0.24)',
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInset.xs
  },
  scheduleContentHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: '14px',
    letterSpacing: '0.14px',
    marginBottom: '8px'
  },
  scheduleContentAvatar: {
    background: `${theme.colorBrand.light} !important`,
    color: `${theme.colors.neutral.lightBase} !important`,
    width: '24px !important',
    height: '24px !important',
    fontSize: '10px !important'
  },
  navContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingStack.xl
    }
  },
  descriptionMainContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: theme.spacingInline.xxs
    }
  },
  mainContainer: {
    padding: theme.spacingInline.xxs,
    minHeight: '100%',
    paddingBottom: '64px !important',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingStack.xxxs
    }
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      width: '100%',
      flex: '0 0 100% !important'
    }
  },
  resourceContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.spacingStack.xxl} !important`
    }
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  buttons: {
    padding: '8px 16px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
