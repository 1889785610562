import { useState, useEffect } from 'react'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import NaturalCompare from 'string-natural-compare'
import { Grid, useTheme } from '@mui/material'
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined'
import { Search } from 'components/design-system'
import ContentLink from 'components/common/ContentLink'
import { EmptyStateComponent, StatusSelector } from 'components/common'
import { IActivityResponse } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import useStyle from './style'
import { Intro, Legend, SearchContainer } from '../../style'

interface IOriginals {
  activitiesAvailable: IActivityResponse[]
  isProjectViewMode: boolean
  isCloeExpand: boolean
  handleSearch: (pattern: string) => void
  handleClear: () => void
  searchRef: React.RefObject<any>
  searchPhrase: string
  contentId: string
  gradeCode: string
  gradeTypeCode: string
  classId: string
}

type orderSortEnum = 'ASC' | 'DESC'
type usedFilterEnum = 'ALL' | 'USED' | 'N_USED'

interface IActivityViewOptions {
  sort: orderSortEnum
  filter: usedFilterEnum
}

const DEFAULT_ACTIVITY_VIEW_OPTION: IActivityViewOptions = {
  sort: 'ASC',
  filter: 'ALL'
}

export const Originals = ({
  activitiesAvailable,
  isProjectViewMode,
  isCloeExpand,
  handleSearch,
  handleClear,
  searchRef,
  searchPhrase,
  contentId,
  gradeCode,
  gradeTypeCode,
  classId
}: IOriginals) => {
  const { t, Trans } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const theme = useTheme()

  const classes = useStyle()

  const [activityViewOptions, setActivityViewOptions] = useState<IActivityViewOptions>(DEFAULT_ACTIVITY_VIEW_OPTION)
  const [activitiesProcessed, setActivitiesProcessed] = useState<IActivityResponse[]>(activitiesAvailable)
  const [disableUSEDSelector, setDisableUSEDSelector] = useState<boolean>(false)
  const [disableN_USEDSelector, setDisableN_USEDSelector] = useState<boolean>(false)

  const handleViewOptions = (activities: IActivityResponse[], sort: orderSortEnum, filter: usedFilterEnum) => {
    const activitiesFiltered: IActivityResponse[] = []
    activities?.forEach(act => {
      if (filter === 'ALL') {
        if (disableUSEDSelector) setDisableUSEDSelector(false)
        if (disableN_USEDSelector) setDisableN_USEDSelector(false)

        activitiesFiltered.push(act)
      }

      if (filter === 'USED' && act?.clonedTimes !== 0) activitiesFiltered.push(act)

      if (filter === 'N_USED' && act?.clonedTimes === 0) activitiesFiltered.push(act)
    })

    const activitiesSorted = activitiesFiltered?.map(cur => {
      // making the title just one to put in alphabetically order
      cur.title = cur.title ?? cur.activity_name
      cur.title = cur.title.trim()
      return cur
    }).sort((a, b) => {
      if (sort === 'ASC') {
        return NaturalCompare(a.title, b.title, { caseInsensitive: true })
      }
      return NaturalCompare(b.title, a.title, { caseInsensitive: true })
    })

    if (activitiesSorted.every(activity => activity.clonedTimes === 0)) setDisableUSEDSelector(true)
    if (activitiesSorted.every(activity => activity.clonedTimes !== 0)) setDisableN_USEDSelector(true)

    setActivitiesProcessed(activitiesSorted)
  }

  const orderStatusOptions = {
    ASC: {
      title: t('Nome - crescente (A - Z)')
    },
    DESC: {
      title: t('Nome - decrescente (Z - A)')
    }
  }

  const usedStatusOption = {
    ALL: {
      title: t('Todas as atividades')
    },
    USED: {
      title: t('Somente utilizadas'),
      disableOption: disableUSEDSelector

    },
    N_USED: {
      title: t('Somente não utilizadas'),
      disableOption: disableN_USEDSelector
    }
  }

  useEffect(() => {
    handleViewOptions(activitiesAvailable, activityViewOptions.sort, activityViewOptions.filter)
  }, [activityViewOptions, searchPhrase])

  return (
    <>
      <Intro>
        {t('Vamos colocar os conhecimentos em prática?')}
        {isProjectViewMode && (
          <Grid item sx={{ pt: 2 }}>
            <Legend>
              {t('Aqui você encontra atividades para reforçar o aprendizado dos estudantes. Os exercícios não contam pontos de avaliação.')}
            </Legend>
            <Legend>
              <Trans i18nKey='AchievementLegendLeft'>
                As <b>atividades de Conquista</b> (marcadas com o ícone
              </Trans>
              <DiamondOutlinedIcon sx={{ position: 'relative', top: '4px', fontSize: theme.font.fontSize.sm, color: theme.colors.information.feedbackMedium }} />
              <Trans i18nKey='AchievementLegendRight'>
                ) são destinadas às aulas mobilizadas por projeto (<b>Expedição</b>).
              </Trans>
            </Legend>
          </Grid>
        )}
      </Intro>
      <SearchContainer container spacing={3}>
        <Grid item xs={12} lg={12} xl={4}>
          <Search
            placeholder='Localizar na página'
            onSubmit={handleSearch}
            onClear={handleClear}
            dataTestId={{
              clear: '',
              submit: ''
            }}
            ref={searchRef}
            showSubmitButton={false}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <StatusSelector
            onChange={async sortOption => {
              setActivityViewOptions(old => ({ ...old, sort: sortOption }))
            }}
            statusOptions={orderStatusOptions}
            customContainerClassName={classes.dropdownContainer}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <StatusSelector
            onChange={async filterOption => {
              setActivityViewOptions(old => ({ ...old, filter: filterOption }))
            }}
            statusOptions={usedStatusOption}
            customContainerClassName={classes.dropdownContainer}
          />
        </Grid>
      </SearchContainer>
      {
        activitiesProcessed.length === 0
          ? <EmptyStateComponent>
            <Grid container flexDirection='column' alignItems='center' sx={{ fontSize: theme.font.fontSize.md, color: theme.colors.neutral.dark30 }}>
              <span style={{ display: 'inline' }}>
                <Trans i18nKey={`Poxa, parece que o termo ${searchPhrase} não foi encontrado.`}>
                  Poxa, parece que o termo <b style={{ color: theme.colorBrand.medium }}>{searchPhrase}</b> não foi encontrado.
                </Trans>
              </span>
              <Trans i18nKey='Você pode tentar outros termos ou verificar se houve algum erro de digitação.'>
                Você pode tentar outros termos ou verificar se houve algum erro de digitação.
              </Trans>
            </Grid>
          </EmptyStateComponent>
          : <Grid container>
            <Grid item xs={12} gap={theme.spacingInline.xxs} display='flex' sx={{
              flexWrap: 'wrap',
              background: theme.colors.neutral.lightBase,
              [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
            }}>
              {
                activitiesProcessed.map((cur: IActivityResponse, i) =>
                  <Grid sx={{
                    maxWidth: '284px',
                    // minWidth: '284px',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '100%',
                      minWidth: '100%'
                    }
                  }} key={i}>
                    <ContentLink
                      id={contentId?.toString()}
                      index={i}
                      activityId={cur.id?.toString()}
                      contentId={contentId}
                      gradeCode={gradeCode}
                      gradeTypeCode={gradeTypeCode}
                      classId={classId}
                      sectionId='14'
                      title={cur.title ?? cur.activity_name}
                      type={cur.type === 'guide' ? ContentTypeEnum.guide : ContentTypeEnum.activity}
                      momentType={cur.moment_type}
                      hasNotification={cur.hasNotification}
                      useType={cur.use_type}
                      suggestedApplicationType={cur.suggested_application_type}
                      dataTestid='activities_select_available_activity'
                      isLast={activitiesProcessed.length - 1 > 0 && activitiesProcessed.length - 1 === i}
                      isProjectViewMode={isProjectViewMode}
                      clonedTimes={cur.clonedTimes}
                      useCloeExpandTranslation={isCloeExpand}
                      quantityQuestions={cur.questions.length}
                      isTemplate
                    />
                  </Grid>)
              }
            </Grid>
          </Grid>
      }
    </>
  )
}
