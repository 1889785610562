import { Grid, Skeleton, useTheme } from '@mui/material'
import {
  Avatar,
  BreadCrumb,
  Button,
  Tag,
  Text,
  Text2
} from 'components/design-system'
import {
  COLLECTIONS,
  DASHBOARD_TEACHER,
  LIBRARY_SEARCH,
  STUDENT_SCHEDULE
} from 'navigation/CONSTANTS'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { getArticle, getResource } from 'services/library'
import * as StrapiService from 'services/resources'
import * as StrapiTypes from 'services/types'
import {
  IClassScheduleResponse,
  IDisciplineResponse,
  resourceTypesNames,
  ISectionResponse
} from 'services/types'
import { StateError, StateLoading } from './components/MachineStates'
import { ResourceThumb } from 'components/common/ResourceThumb'
import { useTranslation } from 'react-i18next'
import { ReactComponent as OriginalCloeIcon } from './assets/cloe_original.svg'
import useStyle from './style'
import { OnlyProfile, Resource } from 'components/common'
import { BookmarkBorder, ShareOutlined } from '@mui/icons-material'
import {
  IArticleResponse,
  IResourceResponse,
  mapToStrapiResource
} from 'services/types/library'
import { useStore } from 'store'
import { MyCloe } from 'navigation/components'
import { LibraryContentSchedule } from 'navigation/components/MyCloe/components'
import {
  selectedArticlesAtom,
  selectedResourcesAtom
} from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentShare/atomStore'
import { useQuery } from 'utils/query'
import { getSchedule } from 'services/schedule'
import { ModalResourceGallery } from 'components/common/ResourceGallery/components/Modal'
import { getDidacticContentSection } from 'services/didactic-content'
import { RenderWhenCondition } from 'utils/wrappers'
import { DidacticContent } from './components/DidacticContentSection'
import { useUpdateAtom } from 'jotai/utils'
import { useGradeDisciplines } from 'services/segments'
import {
  selectedArticlesToSaveAtom,
  selectedResourcesToSaveAtom
} from 'navigation/components/MyCloe/components/LibraryContentSchedule/LibraryContentSave/atomStore'

interface IQueryParams {
  contentId: string
  gradeTypeCode: string
  gradeCode: string
  classId: string
  type: StrapiTypes.IResourceTypesEnum | 'article'
}

export const LibraryContentDetails = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()
  const { profile } = useStore()
  const { contentId, gradeTypeCode, gradeCode, classId, type } =
    useParams<IQueryParams>()
  const searchQuery = useQuery()
  const [resource, setResource] = useState<IResourceResponse>()
  const [resourceStrapi, setResourceStrapi] =
    useState<StrapiTypes.IResourceResponse>()
  const [article, setArticle] = useState<IArticleResponse>()
  const [didacticContentSection, setDidacticContentSection] =
    useState<ISectionResponse>()
  const [schedule, setSchedule] = useState<IClassScheduleResponse>()
  const [state, setState] = useState<'loading' | 'success' | 'error'>('loading')
  const setSelectedResources = useUpdateAtom(selectedResourcesAtom)
  const setSelectedArticles = useUpdateAtom(selectedArticlesAtom)
  const setResourcesSelectedToSave = useUpdateAtom(selectedResourcesToSaveAtom)
  const setArticlesSelectedToSave = useUpdateAtom(selectedArticlesToSaveAtom)
  const [errorOnRequest, setErrorOnRequest] = useState(false)

  const search = searchQuery.get('search')
  const scheduleId = searchQuery.get('scheduleId')
  const collectionName = searchQuery.get('collectionName')
  const collectionId = searchQuery.get('collectionId')
  const collectionMadeByCloe = searchQuery.get('collectionMadeByCloe')

  const fetchResource = async () => {
    const response = await getResource(Number(contentId))
    if (response.success) {
      setResource(response.data)
    } else {
      setErrorOnRequest(true)
    }
  }

  const fetchResourceFromStrapi = async () => {
    const response = await StrapiService.getResource(contentId)
    if (response.success) {
      setResourceStrapi(response.data)
    } else {
      setErrorOnRequest(true)
    }
  }

  const fetchArticle = async () => {
    const response = await getArticle(Number(contentId))
    if (response.success) {
      setArticle(response.data)
    } else {
      setErrorOnRequest(true)
    }
  }

  const fetchDidiactContent = async () => {
    const response = await getDidacticContentSection(contentId)
    if (response.success) {
      setDidacticContentSection(response.data)
    } else {
      setErrorOnRequest(true)
    }
  }

  const fetchSchedule = async () => {
    if (classId && scheduleId) {
      const response = await getSchedule(classId, scheduleId)
      if (response.success) {
        setSchedule(response.data)
      } else {
        setState('error')
      }
    }
  }

  const initialRequest = async () => {
    if (type === 'article') {
      await Promise.all([
        fetchArticle(),
        fetchDidiactContent(),
        fetchSchedule()
      ])
    } else {
      await Promise.all([
        fetchResource(),
        fetchResourceFromStrapi(),
        fetchSchedule()
      ])
    }

    if (!errorOnRequest) {
      setState('success')
    } else {
      setState('error')
    }
  }

  const handleShareLibraryContent = () => {
    if (resource) {
      return setSelectedResources({
        type: 'ADD',
        payload: resource,
        openMyCloe: true
      })
    }
    if (article) {
      return setSelectedArticles({
        type: 'ADD',
        payload: article,
        openMyCloe: true
      })
    }
  }

  const handleSaveLibraryContent = () => {
    if (resource) {
      return setResourcesSelectedToSave({
        type: 'ADD',
        payload: resource,
        openMyCloe: true
      })
    }
    if (article) {
      return setArticlesSelectedToSave({
        type: 'ADD',
        payload: article,
        openMyCloe: true
      })
    }
  }

  useEffect(() => {
    initialRequest()
  }, [])

  const { segments, allAvailableDisciplines } = useGradeDisciplines({})

  const uniqueDisciplines = useMemo(() => {
    const _disciplines = allAvailableDisciplines.reduce<IDisciplineResponse[]>(
      (acum, sub) => {
        sub.disciplines
          .filter(d => d.type === 'discipline')
          .forEach(disc => {
            if (!acum.some(i => i.id === disc.id)) {
              acum.push(disc)
            }
          })
        return acum
      },
      []
    )
    return _disciplines?.sort((a, b) => a.name.localeCompare(b.name))
  }, [allAvailableDisciplines])

  const title = resource?.title ?? didacticContentSection?.title ?? ''
  const madeByCloe = type === 'article' || !!resource?.madeByCloe
  const thumbnail = resource?.thumbnail ?? article?.thumbnail ?? ''
  const disciplines =
    resource?.metadata.disciplines ?? article?.disciplines ?? []
  const libraryGradeTypes =
    resource?.metadata.gradeTypes ?? article?.gradeTypes ?? []
  const comment = resource?.metadata.comment ?? article?.comment ?? ''
  const tags = resource?.metadata.tags ?? article?.tags ?? []

  const render = {
    loading: <StateLoading />,
    error: <StateError />,
    success: (
      <>
        <Grid xs={12} className={classes.navContainer}>
          <RenderWhenCondition condition={!schedule}>
            <BreadCrumb
              current={title.replace(/<\/?[^>]+(>|$)/g, '')}
              crumbs={[
                { title: t('Biblioteca'), link: LIBRARY_SEARCH('') },
                !collectionName
                  ? {
                    title: t('Pesquisar na biblioteca: ') + search,
                    link: LIBRARY_SEARCH(search ?? '')
                  }
                  : {
                    title: collectionName,
                    link: COLLECTIONS(collectionId ?? '', { madeByCloe: collectionMadeByCloe === 'true' ? 'true' : 'false' })
                  }
              ]}
            />
          </RenderWhenCondition>
          <RenderWhenCondition condition={!!schedule}>
            <BreadCrumb
              current={title.replace(/<\/?[^>]+(>|$)/g, '')}
              crumbs={[
                profile?.type === StrapiTypes.IUserSchoolProfileTypeEnum.teacher
                  ? {
                    title: t('Agenda'),
                    link: DASHBOARD_TEACHER(gradeTypeCode, gradeCode, classId)
                  }
                  : { title: t('Agenda'), link: STUDENT_SCHEDULE }
              ]}
            />
          </RenderWhenCondition>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mt={3}
          className={classes.descriptionMainContainer}
        >
          <div>
            <ResourceThumb
              thumbnail={thumbnail}
              type={type as any}
              size='medium'
              originalCloe={madeByCloe}
            />
          </div>
          <div className={classes.descriptionContainer}>
            <Text2
              fontSize='xl'
              fontWeight='semibold'
              lineHeight='sm'
              mobile='lg'
              customColor={theme.colorBrand.dark}
            >
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </Text2>
            <div className={classes.descriptionDetails}>
              <Tag className={classes.typeTag}>
                {type === 'article'
                  ? t('article')
                  : t(
                    resourceTypesNames[
                      resource?.type as StrapiTypes.IResourceTypesEnum
                    ]
                  )}
              </Tag>
              {madeByCloe && (
                <div className={classes.descriptionOriginalCloe}>
                  <OriginalCloeIcon />
                  <Text
                    type='body'
                    size='small'
                    style={{ color: theme.colors.support.colorSupport02 }}
                  >
                    <b>{t('Feito pela Cloe')}</b>
                  </Text>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid container item xs={12} spacing={4} mt={3}>
          <Grid className={classes.contentContainer} item xs={4}>
            <RenderWhenCondition condition={!schedule}>
              <Grid item xs={12}>
                <Text
                  type='body'
                  size='medium'
                  style={{ color: theme.colors.neutral.dark20 }}
                >
                  <b>{t('Recomendado para: ')}</b>
                </Text>
              </Grid>
              <Grid item xs={12} display='flex' flexWrap='wrap' mt={2} gap={1}>
                <RenderWhenCondition condition={!segments.length}>
                  <Skeleton height={36} width={60} />
                </RenderWhenCondition>
                <RenderWhenCondition condition={!uniqueDisciplines.length}>
                  <Skeleton height={36} width={60} />
                </RenderWhenCondition>
                {segments
                  ?.filter(gr => libraryGradeTypes.find(mgr => mgr === gr.id))
                  .map((gr, i) => (
                    <Tag key={i} className={`${classes.detailsTag} segment`}>
                      {gr.name}
                    </Tag>
                  ))}
                {uniqueDisciplines
                  ?.filter(td => disciplines.find(ds => ds === td.id))
                  .map((ds, i) => (
                    <Tag key={i} variant={ds.code} className={classes.disciplineTag}>
                      {t(ds.name)}
                    </Tag>
                  ))}
              </Grid>
            </RenderWhenCondition>
            <RenderWhenCondition condition={!!schedule}>
              <Grid item xs={12}>
                <div className={classes.scheduleContent}>
                  <div className={classes.scheduleContentHeader}>
                    <Avatar
                      className={classes.scheduleContentAvatar}
                      userName={schedule?.user.username}
                    />
                    {schedule?.user.name} {schedule?.user.surname}
                  </div>
                  <Text
                    type='body'
                    size='small'
                    style={{ color: theme.colors.neutral.dark20 }}
                  >
                    {schedule?.content}
                  </Text>
                </div>
              </Grid>
            </RenderWhenCondition>
            <Grid item xs={12} mt={4}>
              <Text
                type='body'
                size='medium'
                style={{
                  color: theme.colors.neutral.dark20
                }}
                dangerouslySetInnerHTML={{
                  __html: comment
                }}
              />
            </Grid>
            <RenderWhenCondition condition={!schedule}>
              <Grid item xs={12} mt={4}>
                <Text
                  type='body'
                  size='medium'
                  style={{ color: theme.colors.neutral.dark20 }}
                >
                  {t('Palavras-chave:')}
                </Text>
              </Grid>
              <Grid item xs={12} display='flex' flexWrap='wrap' mt={1} gap={1}>
                {tags.map((tag, i) => (
                  <NavLink
                    to={LIBRARY_SEARCH(tag)}
                    className={classes.detailsTagLink}
                  >
                    <Tag className={classes.detailsTag} key={i}>
                      {tag}
                    </Tag>
                  </NavLink>
                ))}
              </Grid>
            </RenderWhenCondition>
            <RenderWhenCondition condition={!schedule}>
              <Grid
                item
                xs={12}
                mt={4}
                display='flex'
                justifyContent='space-between'
                gap={2}
                className={classes.buttonContainer}
              >
                <Button
                  onClick={handleShareLibraryContent}
                  endIcon={<ShareOutlined />}
                  size='medium'
                  className={classes.buttons}
                  data-testid={
                    type === 'article'
                      ? 'cloe_super_chapter_open_select_share'
                      : 'cloe_super_resource_open_select_share'
                  }
                >
                  <span className={classes.buttonText}>
                    {t('Compartilhar')}
                  </span>
                </Button>
                <Button
                  onClick={handleSaveLibraryContent}
                  endIcon={<BookmarkBorder />}
                  className={classes.buttons}
                  data-testid={
                    type === 'article'
                      ? 'cloe_super_chapter_open_select_save'
                      : 'cloe_super_resource_open_select_save'
                  }
                >
                  <span className={classes.buttonText}> {t('Salvar')}</span>
                </Button>
              </Grid>
            </RenderWhenCondition>
          </Grid>
          <Grid
            className={classes.resourceContainer}
            item
            sm={8}
            xs={12}
            display='flex'
            justifyContent='center'
          >
            {!!resource && (
              <Resource
                resource={mapToStrapiResource(resource, resourceStrapi)}
              />
            )}
            {!!resource && resource.type === 'gallery' && (
              <ModalResourceGallery />
            )}
            {!!didacticContentSection && (
              <DidacticContent section={didacticContentSection} />
            )}
          </Grid>
        </Grid>
        <OnlyProfile
          profileTypes={[StrapiTypes.IUserSchoolProfileTypeEnum.teacher]}
        >
          <MyCloe>
            <LibraryContentSchedule />
          </MyCloe>
        </OnlyProfile>
      </>
    )
  }

  return (
    <Grid container className={classes.mainContainer}>
      {render[state]}
    </Grid>
  )
}

export default LibraryContentDetails
