import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ISideMenuStyleProps } from '.'
import { ICoverResponse } from 'services/types'

export interface IStyleProps {
  imageUrl: ICoverResponse
  hasNavigationTabs: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  sideMenu: (props: IStyleProps) => ({
    flexDirection: 'column',
    gap: theme.spacingInline.xxxs,
    padding: theme.spacingInline.xxs,

    width: '100%',
    display: 'flex',
    opacity: '1',

    transition: 'width easy-in-out 0.2s, display easy-in-out 0.2s, opacity easy-in-out 0.2s',
    transitionBehavior: 'allow-discrete',

    ...(props.hasNavigationTabs
      ? {
        minHeight: '100vh'
      }
      : {
        maxHeight: '145vh'
      }),

    top: 0,
    position: 'sticky',
    boxShadow: theme.shadowLevels.level3,
    borderRadius: theme.borderRadius.md,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      left: 0,
      width: '100%',
      height: '100vh',

      zIndex: '9999',

      padding: theme.spacingInline.xxxs,

      background: theme.colorBrand.lightest,
      borderRadius: '0px !important'
    }
  }),
  sideMenuClosed: {
    width: '0px !important',
    display: 'none !important',
    opacity: '0 !important'
  },
  flexContent: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '145px'
  },
  backLink: {
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.xxs,
    overflow: 'hidden',
    flexWrap: 'wrap',
    width: '100%',

    margin: 0,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',

    // larger screens
    [theme.breakpoints.up('sm')]: {
      padding: 0
    },

    '& .MuiSvgIcon-root': {
      marginRight: theme.spacingInline.nano,
      color: theme.colorBrand.medium,
      width: theme.font.fontSize.xxs,
      height: theme.font.fontSize.xxs
    }
  },
  mobileCloseButton: {
    padding: `0 ${theme.spacingInline.nano} !important`,
    boxSizing: 'content-box !important' as 'content-box',
    width: '24px !important',
    color: theme.colorBrand.dark,
    minWidth: 'auto !important',

    '& svg': {
      color: theme.colors.information.feedbackDarkest
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  backgroundImageContainer: {
    width: '100%',
    position: 'relative',
    paddingTop: '38.46%', // This maintains the aspect ratio of 312/120 (120/312 * 100)
    flexShrink: 0,

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    borderRadius: theme.borderRadius.md,
    backgroundImage:
      ({ imageUrl }: ISideMenuStyleProps) =>
        imageUrl?.formats?.small?.url ??
        `url(${imageUrl?.formats?.medium?.url})`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',

    // mobile
    [theme.breakpoints.up('sm')]: {
      backgroundImage:
        ({ imageUrl }: ISideMenuStyleProps) =>
          imageUrl?.formats?.large
            ? `url(${imageUrl?.formats?.large?.url})`
            : `url(${imageUrl?.formats?.medium?.url})`

    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  disciplinesStatusContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacingInline.nano
  },
  contentUnitStatus: {
    fontSize: theme.font.fontSize.xs
  },
  activityTitleWrapper: {
    '& > div': {
      border: 'none',
      minHeight: 'unset',
      cursor: 'default',
      '& > div:last-child': {
        display: 'none'
      }
    }
  },
}))
