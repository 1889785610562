import { Grid } from '@mui/material'
import cloeAvatar from 'assets/cloe_avatar_empty_state.webp'
import useStyle from './style'

type EmptyStateComponentProps = {
  children?: React.ReactNode
  errorMessage: string
}

export const EmptyStateComponent = ({ children, errorMessage }: EmptyStateComponentProps) => {
  const classes = useStyle()

  return (
    <Grid container>
      <Grid item xs={12} mt={4} mb={4} display='flex' justifyContent='center'>
        <img src={cloeAvatar} width={242} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.errorMessage}>
          {errorMessage}
        </div>
      </Grid>
      {children}
    </Grid>
  )
}

export default EmptyStateComponent
