import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Search, Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import { useHistory } from 'react-router-dom'
import { LIBRARY_SEARCH } from 'navigation/CONSTANTS'
import { useEffect } from 'react'
import { CollectionsSection } from 'components/common'
import { MyClassPlans } from './components'
import { selectedLibraryTabAtom, updateSearchRequestAtom } from 'components/common/CloeMaterialSearch/atomStore'
import { useUpdateAtom } from 'jotai/utils'

export const LibraryHome = () => {
  const { t } = useTranslation()
  const classes = useStyle({ useWorm: true })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()
  const setSearchRequest = useUpdateAtom(updateSearchRequestAtom)
  const setSelectedLibraryTabAtom = useUpdateAtom(selectedLibraryTabAtom)

  useEffect(() => {
    setSearchRequest({ type: 'RESET' })
    setSelectedLibraryTabAtom(0)
  }, [])

  return (
    <Grid container p={3} className={classes.libraryContainer}>
      <Grid item xs={12}>
        <Text type='heading' size='medium' color='medium' style={{ fontWeight: theme.font.fontWeight.semibold }}>
          {t('Biblioteca')}
        </Text>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Search
          placeholder={
            isMobile
              ? t('Pesquisar')
              : t('Pesquise conteúdos por palavras-chave. Ex: gêneros textuais')
          }
          dataTestId={{
            clear: 'cloe_super_library_clear_search',
            submit: 'cloe_super_library_submit_search'
          }}
          onSubmit={(pattern) => history.push(LIBRARY_SEARCH(pattern))}
        />
      </Grid>
      <div className={classes.contentSections}>
        <CollectionsSection
          title={t('Coleções da Cloe')}
          originalCloe
          dataTestId='cloe_super_library_cloe_collections'
          prevButtonClassName='colecoesCloePrev'
          nextButtonClassName='colecoesCloeNext'
        />
        <CollectionsSection
          title={t('Minhas coleções')}
          dataTestId='cloe_super_library_personal_collections'
          prevButtonClassName='colecoesHomePrev'
          nextButtonClassName='colecoesHomeNext'
        />
        <MyClassPlans />
      </div>
    </Grid>

  )
}
