import * as SvgIcons from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { Alert, Icon, Tag } from 'components/design-system'
import { NavLink } from 'react-router-dom'
import { IUserSchoolProfileTypeEnum, ActivityUseTypeEnum } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import useStyles from './styles'
import dayjs from 'dayjs'
import { Fragment, useContext } from 'react'
import Grid from '@mui/material/Grid'
import SectionContext from 'pages/ContentUnit/provider/SectionProvider'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined'
import {
  CONTENT_UNIT_STUDENT_ACTIVITY,
  CONTENT_UNIT_STUDENT_EVALUATION,
  CONTENT_UNIT_STUDENT_CONTENT,
  CONTENT_UNIT_TEACHER_ACTIVITY,
  CONTENT_UNIT_TEACHER_EVALUATION,
  CONTENT_UNIT_TEACHER_CONTENT,
  CONTENT_UNIT_STUDENT_EXPEDITION
} from 'navigation/CONSTANTS'
import { useStore } from 'store'
import { maxLength } from 'utils/string'
import OnlyOffline from 'contentCacheManager/components/OnlyOffline'
import OnlyAnswered from 'contentCacheManager/components/OnlyAnswered'
import { deleteAnswer, IAnswerCachedStatus } from 'contentCacheManager'
import { Tooltip } from 'components/common'
import Appcues from 'utils/appcues'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { ReactComponent as CalendarClock } from 'assets/calendar-clock.svg'

export interface ContentLinkProps {
  id: string
  index?: number
  sectionId?: string
  activityId?: string
  title: string
  type: ContentTypeEnum
  suggestedApplicationType?: 'activity' | 'evaluation'
  contentId?: string
  classId?: string
  gradeTypeCode?: string
  gradeCode?: string
  momentType?: 'home' | 'during_class'
  statusClosed?: boolean
  statusReleased?: boolean
  notAnswered?: boolean
  isPending?: boolean
  isProgramed?: boolean
  awaitingNote?: boolean
  useType?: string
  showTypeTag?: boolean
  isAvailable?: boolean
  isLink?: boolean
  newTab?: boolean
  isEdited?: boolean
  hasNotification?: boolean
  dataTestid?: string
  yellowTags?: string[]
  isLast?: boolean
  isProjectViewMode?: boolean
  isExpeditionView?: boolean
  useCloeExpandTranslation?: boolean
  endDate?: string
  clonedTimes?: number
  isTemplate?: boolean
  quantityQuestions?: number
  isLocked?: boolean
}

export const ContentLink: React.FC<ContentLinkProps> = (
  props: ContentLinkProps
) => {
  const {
    id,
    index,
    sectionId,
    activityId,
    contentId,
    gradeCode,
    gradeTypeCode,
    classId,
    type,
    suggestedApplicationType,
    title,
    momentType,
    statusClosed,
    statusReleased,
    // notAnswered,
    isPending,
    isProgramed,
    // awaitingNote,
    showTypeTag,
    isAvailable,
    isEdited,
    isLink = true,
    newTab,
    hasNotification,
    dataTestid,
    useType,
    isProjectViewMode,
    yellowTags,
    isLast,
    useCloeExpandTranslation,
    isExpeditionView,
    endDate,
    clonedTimes,
    isTemplate,
    quantityQuestions,
    isLocked
  } = props

  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: useCloeExpandTranslation ? 1 : 0 })
  const { setSectionId } = useContext(SectionContext)
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const theme = useTheme()
  const classes = useStyles(props)
  const showAchievementTag = (profileType === IUserSchoolProfileTypeEnum.teacher) && isProjectViewMode && (useType === ActivityUseTypeEnum.ACHIEVEMENT)

  const contentTypes = {
    reading: {
      icon: SvgIcons.DescriptionOutlined,
      label: t('Conteúdo'),
      tagColor: theme.colors.contentType.default
    },
    activity: {
      icon: SvgIcons.ExtensionOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    },
    evaluation: {
      icon: SvgIcons.BallotOutlined,
      label: t('Avaliação'),
      tagColor: theme.colors.contentType.evaluation
    },
    guide: {
      icon: SvgIcons.BorderColorOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    }
  }

  const { icon: SvgIcon, label: contentTypeLabel } = contentTypes[type]

  const getLink = () => {
    let outputLink = ''
    if (profileType === IUserSchoolProfileTypeEnum.student) {
      if (suggestedApplicationType === ContentTypeEnum.activity) {
        if (isExpeditionView) {
          outputLink = `${CONTENT_UNIT_STUDENT_EXPEDITION(id, activityId)}`
        } else {
          outputLink = `${CONTENT_UNIT_STUDENT_ACTIVITY(id, activityId)}`
        }
      } else if (suggestedApplicationType === ContentTypeEnum.evaluation) {
        if (isExpeditionView) {
          outputLink = `${CONTENT_UNIT_STUDENT_EXPEDITION(id, activityId)}`
        } else {
          outputLink = `${CONTENT_UNIT_STUDENT_EVALUATION(id, activityId)}`
        }
      } else if (type === ContentTypeEnum.reading) {
        outputLink = CONTENT_UNIT_STUDENT_CONTENT(id, sectionId)
      }
    } else if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      if (suggestedApplicationType === ContentTypeEnum.activity) {
        outputLink = `${CONTENT_UNIT_TEACHER_ACTIVITY(
          gradeTypeCode as string,
          gradeCode as string,
          classId,
          contentId,
          activityId
        )}`
      } else if (suggestedApplicationType === ContentTypeEnum.evaluation) {
        outputLink = `${CONTENT_UNIT_TEACHER_EVALUATION(
          gradeTypeCode as string,
          gradeCode as string,
          classId,
          contentId,
          activityId
        )}`
      } else if (type === ContentTypeEnum.reading) {
        outputLink = CONTENT_UNIT_TEACHER_CONTENT(
          gradeTypeCode as string,
          gradeCode as string,
          classId,
          contentId,
          sectionId
        )
      }
    }
    return outputLink
  }

  const link = getLink()

  const yellowTagsRender = () => {
    return yellowTags?.length
      ? yellowTags.map(tag => (
        <Tag
          color={theme.colors.neutral.darkBase}
          className={classes.tag}
          background={theme.colors.warning.feedbackMedium}
        >
          {tag}
        </Tag>
      ))
      : <Fragment />
  }

  const handleEventTracking = () => {
    interface IEvents {
      [profile: string]: {
        activity: () => void
        evaluation: () => void
      }
    }
    const events: IEvents = {
      teacher: {
        activity: () => {
          if (isLast) {
            Appcues.track('prof_ultima_atividade')
          }
          if (index !== 0) {
            Appcues.track('prof_segunda_atividade') // embora nome seja segundo, deve ser disparado em todos menos no primeiro
          }
        },
        evaluation: () => {
          if (isLast) {
            Appcues.track('prof_ultima_avaliacao')
          }
          if (index !== 0) {
            Appcues.track('prof_segunda_avaliacao') // embora nome seja segundo, deve ser disparado em todos menos no primeiro
          }
        }
      }
    }

    if (suggestedApplicationType && profileType && profileType in events) {
      events[profileType][suggestedApplicationType]()
    }
  }

  const WrapperComponent: React.FC = ({ children }) =>
    isLink
      ? <NavLink
        data-testid={dataTestid}
        to={link}
        className={classes.contentLink}
        target={newTab ? '_blank' : undefined}
        onClick={(e) => {
          if (isLocked) {
            e.preventDefault()
          } else {
            setSectionId(sectionId)
            handleEventTracking()
          }
        }}
      >
        {children}
      </NavLink>
      : <div data-testid={dataTestid} className={classes.contentLink}>
        {children}
      </div>

  return (
    <WrapperComponent>
      <div className={classes.contentLinkHead} style={{ paddingRight: isProgramed ? '32px' : 0 }}>
        {showAchievementTag
          ? <Tooltip text={t('Atividade de Conquista')} minWidth={142} maxWidth={142} component={<SvgIcons.DiamondOutlined className={classes.contentLinkIcon} sx={{ color: theme.colors.information.feedbackMedium }} />} />
          : <SvgIcon className={classes.contentLinkIcon} />
        }
        <Grid item className={classes.contentLinkTitle}>
          {isTemplate && <span style={{ textTransform: 'uppercase' }}>{t('[Original]')}</span>}
          <div>
            {maxLength(title, isTemplate ? 22 : 35)}
          </div>
        </Grid>
        {
          (isLocked && !isProgramed) &&
          <div className={classes.contentLinkIsLocked}>
            <Tooltip text={t('As avaliações só podem ser visualizadas após liberação do professor.')} component={<SvgIcons.LockOutlined />} />
          </div>
        }
        {
          isAvailable &&
          <div className={classes.contentLinkIsAvailable}>
            <SvgIcons.LockOutlined />
          </div>
        }
        {
          isProgramed &&
          <div className={classes.contentLinkIsAvailable}>
            <Tooltip
              text={t(`${contentTypeLabel} programada para liberação posterior.`)}
              minWidth={142}
              maxWidth={198}
              component={<CalendarClock className={classes.contentLinkProgramedIcon} />}
            />
          </div>
        }
        {hasNotification && (
          <Grid
            item
            xs={2}
            lg={2}
            display='flex'
            gap={2}
            sx={{
              gridArea: 'interact',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              justifyItems: 'center'
            }}
          >
            <div className={classes.badgeRead}></div>
          </Grid>
        )}
      </div>
      {quantityQuestions &&
        <Grid item className={classes.quantityQuestions}>
          <span>{quantityQuestions} {quantityQuestions <= 1 ? 'questão' : 'questões'}</span>
        </Grid>
      }
      {isPending && (
        <div
          className={classes.warningLabels}
          style={{ color: theme.colors.warning.feedbackDark }}
        >
          {t('Atividade em aberto')}
        </div>)
      }
      <OnlyOffline forceMobile>
        <OnlyAnswered
          activity={String(activityId)}
          render={data => {
            const error = data.find(
              answer => answer.status === IAnswerCachedStatus.SENT_ERROR
            )
            const success = data.find(
              answer => answer.status === IAnswerCachedStatus.SENT_SUCCESS
            )
            if (error) {
              deleteAnswer(error)
              return (
                <Alert
                  content={t('Ocorreu um erro no envio das respostas.')}
                  severity='error'
                  style={{ margin: '0px 10px 20px 10px' }}
                />
              )
            }
            if (success) {
              deleteAnswer(success)
              return (
                <Alert
                  content={t('Respostas enviadas com sucesso')}
                  severity='success'
                  style={{ margin: '0px 10px 20px 10px' }}
                />
              )
            }

            return (
              <Alert
                content={t(
                  'As respostas salvas serão enviadas assim que a internet voltar'
                )}
                severity='info'
                style={{ margin: '0px 10px 20px 10px' }}
              />
            )
          }}
        />
      </OnlyOffline>
      <div className={classes.contentLinkTags}>
        {showTypeTag && (
          <Tag
            color={theme.colors.neutral.lightBase}
            className={classes.tag}
            background={isLocked ? theme.colors.neutral.light40 : contentTypes[type].tagColor}
          >
            {contentTypes[type].label}
          </Tag>
        )}
        {momentType && (
          <Tag
            color={theme.colors.neutral.lightBase}
            className={classes.tag}
            background={isLocked ? theme.colors.neutral.light40 : theme.colors.contentType.activity}
          >
            {t(momentType)}
          </Tag>
        )}
        {
          statusClosed === true && (
            <Tag
              color={theme.colors.neutral.lightBase}
              className={classes.tag}
              background='#C91212'
            >
              {t('Envios encerrados')}
            </Tag>
          )
        }
        {
          clonedTimes !== undefined && (
            <Tag
              color={theme.colorBrand.light}
              className={`${classes.tag} ${classes.usedTimes}`}
              background={'transparent'}
            >
              {`Utilizada ${clonedTimes} vez(es)`}
            </Tag>
          )
        }
        {endDate && (
          <Tag
            color={theme.colors.neutral.lightBase}
            className={classes.tag}
            background={theme.colors.neutral.dark30}
          >
            <Icon iconColor={theme.colors.neutral.lightBase} size='small' sx={{ fontSize: theme.font.fontSize.sm }}>
              <InsertInvitationOutlinedIcon />
            </Icon>
            {dayjs(endDate).format('DD/MM/YYYY')}
          </Tag>
        )}
        {statusReleased === true && (
          <Tag
            color={theme.colors.neutral.lightBase}
            className={classes.tag}
            background='#00B086'
          >
            {t('Corrigida')}
          </Tag>
        )}
        {isEdited === true && (
          <Tag
            color={theme.colorBrand.light}
            className={classes.tagIsEdited}
            background={theme.colors.neutral.lightBase}
          >
            {t('Editado')}
          </Tag>
        )}
        {yellowTagsRender()}
      </div>
    </WrapperComponent>
  )
}

export default ContentLink
