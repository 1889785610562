import useStyle from './style'
import { Icon, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import { ExploreCard } from './components'
import { ReactComponent as StockMediaIcon } from 'assets/stock-media.svg'
import { CLASS_PLAN_CREATE, LIBRARY_HOME, PROGRAM_CONTENT } from 'navigation/CONSTANTS'
import { useLmsPlatform } from 'navigation/hooks/useLmsPlatform/useLmsPlatform'
import { useStore } from 'store'

export const ExploreSection: React.FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const { profile } = useStore()
  const { getLink } = useLmsPlatform(t, profile)

  return (
    <section className={classes.homeSection}>
      <Text2 fontSize='lg' fontWeight='semibold' colorbrand='medium'>
        {t('Explorar')}
      </Text2>

      <div className={classes.exploreCardsContainer}>
        <ExploreCard
          title={t('Criar plano de aula')}
          description={t('Utilize nossa assistente para criar planos de aula customizados.')}
          iconComponent={
            <Icon titleAccess={t('Criar plano de aula')} size='medium'>
              <AutoAwesomeOutlinedIcon />
            </Icon>
          }
          linkTo={CLASS_PLAN_CREATE(0)}
          dataTestId='cloe_super_home_explorer_create_class_plan'
        />

        <ExploreCard
          title={t('Biblioteca')}
          description={t('Pesquise, organize e compartilhe os recursos educacionais da Cloe.')}
          iconComponent={
            <Icon titleAccess={t('Biblioteca')} size='medium'>
              <StockMediaIcon />
            </Icon>
          }
          linkTo={LIBRARY_HOME}
          dataTestId='cloe_super_home_explorer_library'
        />

        <ExploreCard
          title={t('Programa Cloe')}
          description={t('Alinhado à BNCC e com aprendizagem ativa em todas as áreas do conhecimento.')}
          iconComponent={
            <Icon titleAccess={t('Conteúdo programático')} size='medium'>
              <RouteOutlinedIcon />
            </Icon>
          }
          linkTo={PROGRAM_CONTENT}
          dataTestId='cloe_super_home_explorer_cloe_program'
        />

        <ExploreCard
          title={t('Formação de professores')}
          description={t('Promova a aprendizagem ativa em suas aulas e saiba mais sobre o uso da plataforma.')}
          iconComponent={
            <Icon titleAccess={t('Formação de professores')} size='medium'>
              <LocalLibraryOutlinedIcon />
            </Icon>
          }
          onClick={async () => await getLink()}
          dataTestId='cloe_super_home_teacher_training'
        />
      </div>
    </section>
  )
}
