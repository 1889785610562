import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import useStyle from './style'

export interface ICrumbProps {
  title: string
  link: string
}

export interface IBreadCrumbProps {
  crumbs: ICrumbProps[]
  current: string
}

export const BreadCrumb = ({ crumbs, current }: IBreadCrumbProps) => {
  const classes = useStyle()
  return (
    <Breadcrumbs aria-label='breadcrumb' separator='>' className={classes.crumb}>
      {
        crumbs.map((crumb, i) => (
          <Link key={i} className={classes.crumb} to={crumb.link} color='inherit' href='/'>
            {crumb.title}
          </Link>
        ))
      }
      <Link to={`${window.location.pathname}${window.location.search}`} className={classes.crumb}>
        {current}
      </Link>
    </Breadcrumbs>
  )
}
