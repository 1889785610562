import React, { useEffect, useRef, useState } from 'react'
import { Box, Dialog, Grid, List, ListItem, useMediaQuery, useTheme } from '@mui/material'
import { Button, Text2 } from 'components/design-system'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunchOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import { Tooltip } from 'pages/Dashboard/teacher/Contents/components/ListContentUnit/components/ContentCardLong/components/Card/components/Tooltip'
import { EmptyStateComponent, Text } from 'components/common'
import cloeAvatar from 'assets/cloe_avatar_not_found.webp'
import { DisciplineCodeEnum, ICloeProgramResponse, IContentUnitSkill } from 'services/types'
import { IClassesTaught } from 'services/types/taught-disciplines'
import { ModalProgramCloe } from '../ModalProgramCloe'
import { useAtom } from 'jotai'
import { searchPhraseAtom } from 'pages/ProgramContent/components/ProgramCloeView/atomStore'
import Mark from 'mark.js'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

interface ICloeCardContent {
  cardContent: ICloeProgramResponse
  isOpenContent: boolean
  onCloseContent: (contentId: number) => void
  classesTaught: IClassesTaught | null
}

enum TabIndexEnum {
  CURRICULO = 0,
  TRIADE = 1
}

export const CloeCardContent = ({ cardContent, isOpenContent, onCloseContent, classesTaught }: ICloeCardContent) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle({ isOpenContent })

  const [tabIndex, setTabIndex] = useState<TabIndexEnum>(TabIndexEnum.CURRICULO)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [searchPhrase] = useAtom(searchPhraseAtom)
  const contentRef = useRef<HTMLDivElement>(null)

  const {
    title,
    classes_count,
    suggested_position,
    skillTaxonomies,
    triad,
    id
  } = cardContent
  const curricularContent = cardContent?.grouped_knowledge ?? []
  const bnccSkill = skillTaxonomies.bnccSkill ?? []
  const skills = skillTaxonomies.skills ?? []

  const showCurricularTab = !!(curricularContent?.length > 0 || skills?.length || bnccSkill?.length || Object.keys(skillTaxonomies).length > 2)

  const handleOpen = () => setIsOpenModal(true)

  const handleCloseModal = () => setIsOpenModal(false)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabIndexEnum) => {
    event.preventDefault()
    event.stopPropagation()
    setTabIndex(newValue)
  }

  window.addEventListener('DOMNodeInserted', (event: any) => {
    if (event.target?.innerHTML?.indexOf('cloeCardContentComponent') > -1) {
      setMark()
    }
    if (event.target?.innerHTML?.indexOf('tooltip') > -1) {
      setMark()
    }
  })

  const setMark = () => {
    const domSelectedToHighlight = document?.querySelectorAll('#cloeCardContentComponent, [role="tooltip"]') ?? ''
    const markInstance = new Mark(domSelectedToHighlight)
    markInstance.unmark({
      done: () => {
        const text = searchPhrase ?? ''
        markInstance.mark(text)
      }
    })
  }

  const validate = (skill: IContentUnitSkill) => {
    if (shoetest.test(searchPhrase, skill.name)) {
      return 'highlight'
    }

    return ''
  }

  useEffect(() => {
    setMark()
  }, [searchPhrase, isOpenContent, tabIndex])

  useEffect(() => {
    if (isOpenContent && contentRef.current) {
      contentRef.current.focus()
    }
  }, [isOpenContent])

  const NotFoundState = () => (
    <Grid container alignItems='center' justifyContent='space-between' paddingBottom={theme.spacingStack.md}>
      <EmptyStateComponent
        ErrorAvatar={<img src={cloeAvatar} width={200} />}
      >
        <Grid textAlign='center' container flexDirection='column' alignItems='center'>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark30}
          >
            {t('Currículo da unidade não encontrado.')}
          </Text2>
        </Grid>
      </EmptyStateComponent>
    </Grid>
  )

  const CurriculumContent = () => {
    const skillTypes = [
      'thematic_unit',
      'knowledge_object',
      'bncc_skill',
      'learning_and_development_goals',
      'experience_area',
      'experience_field',
      'bncc_axis',
    ]

    const customSkills = Object.keys(skillTaxonomies).filter(type => skillTypes.includes(type))
    return (
      <>
        {
          bnccSkill?.length
            ? (
              <Box className={classes.smallContainer}>
                <Text2
                  fontSize='xxs'
                  fontWeight='bold'
                  lineHeight='xs'
                  mobile='xxs'
                  customColor={theme.colors.neutral.dark20}
                >
                  {t('Habilidades BNCC')}
                </Text2>
                <Box className={classes.bnccContainer}>
                  {bnccSkill?.map((item, index) => {
                    return (
                      <Tooltip
                        key={index}
                        label={item.code}
                        text={item.name}
                        className={`${classes.tag} ${validate(item)}`}
                      />
                    )
                  })}
                </Box>
              </Box>
            )
            : <></>
        }
        {
          skills?.length
            ? (
              <>
                <Box className={classes.smallContainer}>
                  <Text2
                    fontSize='xxs'
                    fontWeight='bold'
                    lineHeight='xs'
                    mobile='xxs'
                    customColor={theme.colors.neutral.dark20}
                  >
                    {t('Habilidades Camino')}
                  </Text2>
                  <Box className={classes.bnccContainer}>
                    {skills?.map((item, index) => {
                      return (
                        <Tooltip
                          key={index}
                          label={item.code}
                          text={item.name}
                          className={`${classes.tag} ${validate(item)}`}
                        />
                      )
                    })}
                  </Box>
                </Box>

              </>
            )
            : <></>
        }
        <div className={classes.customSkillsContainer}>
          {
            customSkills.map(type => {
              if (type === 'bncc_skill' && !cardContent.disciplines?.map(d => d.code).includes(DisciplineCodeEnum.BI)) {
                return <></>
              }
              // @ts-expect-error:next-line
              const elements = skillTaxonomies?.[type]

              if (!elements?.length) return <></>

              return (
                <div>
                  <div className={classes.sectionTitle}>
                    {t(type)?.toString()}
                  </div>
                  <ul className={classes.overviewSection}>
                    {
                      elements.map((el: any) =>
                        <li key={el.code}>
                          <Text content={el.name} />
                        </li>)
                    }
                  </ul>
                </div>
              )
            })
          }
        </div>
        {
          curricularContent?.length
            ? (
              <Box sx={{ px: theme.spacingInline.nano }}>
                <Text2
                  fontSize='lg'
                  fontWeight='semibold'
                  lineHeight='sm'
                  mobile='lg'
                  customColor={theme.colorBrand.dark}
                >
                  {t('Conteúdo')}
                </Text2>
                <Box>
                  {curricularContent?.map((area, index) => (
                    <Box key={index}>
                      <Text2
                        fontSize='md'
                        fontWeight='semibold'
                        lineHeight='sm'
                        mobile='md'
                        customColor={theme.colors.neutral.dark10}
                      >
                        {area.knowledge_area}
                      </Text2>
                      {area.knowledge_subarea.map((subarea, subIndex) => (
                        <Box key={subIndex}>
                          <Text2
                            fontSize='sm'
                            fontWeight='medium'
                            lineHeight='xs'
                            mobile='sm'
                            customColor={theme.colors.neutral.dark10}
                          >
                            {subarea.name.toUpperCase()}
                          </Text2>
                          <List sx={{ listStyleType: 'disc' }}>
                            {subarea.didactic_contents.map((contentItem, contentIndex) => (
                              <ListItem sx={{ py: '0px', px: theme.spacingInline.xxs }}>
                                <Text2
                                  key={contentIndex}
                                  fontSize='sm'
                                  fontWeight='medium'
                                  lineHeight='xs'
                                  mobile='sm'
                                  customColor={theme.colors.neutral.dark10}
                                  sx={{ display: 'list-item' }}
                                >
                                  {contentItem.name}
                                </Text2>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            )
            : <></>
        }
        <Box className={classes.cardButtonContainer}>
          <Button
            endIcon={<ArrowForwardIcon fontSize='small' />}
            variant={'primary'}
            data-testid={tabIndex === TabIndexEnum.CURRICULO ? 'program_cloe_select_curriculum_content_unit' : 'program_cloe_select_triade_content_unit'}
            className={classes.cardButton}
            onClick={handleOpen}
          >
            {t('Ir para a unidade')}
          </Button>
        </Box>
      </>
    )
  }

  const CurriculumSection = () => {
    return (
      <>
        {showCurricularTab ? <CurriculumContent /> : <NotFoundState />}
      </>
    )
  }

  const TriadContent = () => {
    return (
      <Box>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='sm'
          mobile='lg'
          customColor={theme.colorBrand.dark}
        >
          {t('Tríade')}
        </Text2>
        {triad.map((item, index) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Text2
                fontSize='md'
                fontWeight='semibold'
                lineHeight='sm'
                mobile='md'
                customColor={theme.colors.neutral.dark10}
              >
                {item.title}
              </Text2>
              <Text2
                key={index}
                fontSize='sm'
                fontWeight='medium'
                lineHeight='xs'
                mobile='sm'
                customColor={theme.colors.neutral.dark10}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: item.text ?? '' }}
                />
              </Text2>
            </Box>
          )
        })}
        <Box className={classes.cardButtonContainer}>
          <Button
            endIcon={<ArrowForwardIcon fontSize='small' />}
            variant={'primary'}
            data-testid='program_cloe_see_resume'
            className={classes.cardButton}
            onClick={handleOpen}
          >
            {t('Ir para a unidade')}
          </Button>
        </Box>
      </Box>
    )
  }

  const ContentCardTabs = () => {
    return (
      <Box className={classes.tabsContainer}>
        <Box className={classes.tabsButtonContainer}>
          <Button
            onClick={e => handleChangeTab(e, TabIndexEnum.CURRICULO)}
            startIcon={<LibraryBooksIcon />}
            variant={'primary'}
            data-testid='program_cloe_select_menu_curriculum'
            className={classes.tabsButtons}
            sx={{
              backgroundColor: tabIndex === TabIndexEnum.CURRICULO ? theme.colorBrand.medium + '!important' : theme.colorBrand.lightest + '!important',
              color: tabIndex === TabIndexEnum.CURRICULO ? theme.colors.neutral.lightBase + '!important' : theme.colorBrand.dark + '!important'
            }}
          >
            {t('Currículo')}
          </Button>
        </Box>
        {triad &&
          <Box className={classes.tabsButtonContainer}>
            <Button
              onClick={e => handleChangeTab(e, TabIndexEnum.TRIADE)}
              startIcon={<RocketLaunchIcon />}
              variant={'primary'}
              data-testid='program_cloe_select_menu_triade'
              className={classes.tabsButtons}
              sx={{
                backgroundColor: tabIndex === TabIndexEnum.TRIADE ? theme.colorBrand.medium + '!important' : theme.colorBrand.lightest + '!important',
                color: tabIndex === TabIndexEnum.TRIADE ? theme.colors.neutral.lightBase + '!important' : theme.colorBrand.dark + '!important'
              }}
            >
              {t('Tríade')}
            </Button>
          </Box>}
      </Box>
    )
  }

  const CloeCardContentComponent = () => {
    return (
      <Box id='cloeCardContentComponent' className={classes.contentContainer} ref={contentRef} tabIndex={-1}>
        {!isMobile && <ContentCardTabs />}
        <Box sx={{ padding: theme.spacingInset.xs, ...(isMobile && { overflow: 'auto' }) }} className={classes.textContainer}>
          <Box className={classes.smallContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
              <Text2
                fontSize='xs'
                fontWeight='medium'
                lineHeight='xxs'
                mobile='xs'
                customColor={theme.colors.neutral.dark40}
              >
                {showCurricularTab ? t('Unidade {{suggested_position}} | {{classes_count}} aulas', { suggested_position, classes_count }) : ''}
              </Text2>
              <Button
                startIcon={<CloseIcon />}
                variant={'ghost'}
                data-testid={tabIndex === TabIndexEnum.CURRICULO ? 'program_cloe_select_curriculum_close' : 'program_cloe_select_triade_close'}
                className={classes.closeButton}
                onClick={() => onCloseContent(id)}
              >
                {t('Fechar')}
              </Button>
            </Box>
            <Text2
              fontSize='xl'
              fontWeight='semibold'
              lineHeight='md'
              mobile='xl'
              customColor={theme.colorBrand.medium}
            >
              {(tabIndex === TabIndexEnum.TRIADE || (showCurricularTab && tabIndex === TabIndexEnum.CURRICULO)) ? title : ''}
            </Text2>
          </Box>
          <Box className={classes.session}>
            {tabIndex === TabIndexEnum.CURRICULO ? <CurriculumSection /> : <TriadContent />}
            {isMobile && <TriadContent />}
          </Box>
        </Box>
      </Box >
    )
  }

  return (
    <>
      {
        isMobile
          ? (
            <Dialog
              fullScreen
              open={isOpenContent}
              onClose={onCloseContent}
              aria-labelledby='fullscreen-dialog-title'
            >
              <CloeCardContentComponent />
            </Dialog>
          )
          : <CloeCardContentComponent />
      }
      <ModalProgramCloe isOpen={isOpenModal} classesTaught={classesTaught} contentId={id} onClose={handleCloseModal} />
    </>
  )
}
