import useStyle from './style'
import { Alert, Button, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { MyClassPlanCard, MyClassPlanCardSkeleton } from './components/MyClassPlanCard'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { CLASS_PLAN_CREATE } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getAllClassPlans } from 'services/classPlan/classPlanService'
import { toast } from 'components/design-system/Toast/manager'
import { IClassPlanItem } from 'services/types'
import { useAuth } from 'navigation/ProvideAuth'
import { SwiperCarousel } from 'components/common'

export enum STATES {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export const MyClassPlans: React.FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const history = useHistory()
  const [stateMachine, setStateMachine] = useState<STATES>(STATES.IDLE)
  const [classPlans, setClassPlans] = useState<IClassPlanItem[]>([])
  const auth = useAuth()

  useEffect(() => {
    fetchClassPlans()
  }, [])

  const fetchClassPlans = async () => {
    try {
      setStateMachine(STATES.LOADING)

      const response = await getAllClassPlans()

      if (!response.success) throw new Error()

      setClassPlans(response?.data?.results ?? [])

      setStateMachine(STATES.READY)
    } catch (err: any) {
      setStateMachine(STATES.ERROR)

      toast.handler({
        content: t('Não conseguimos localizar o plano de aula. Tente novamente mais tarde!'),
        duration: 1000,
        severity: 'error'
      })
    }
  }

  const handleCreateClassPlan = () => {
    history.push(CLASS_PLAN_CREATE(0))
  }

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      // data-testid='followup_reload'
      onClick={fetchClassPlans}
    >
      {t('Recarregar')}
    </Button>
  )

  const LoadingState = () => (
    <div className={classes.exploreCardsContainer}>
      <MyClassPlanCardSkeleton />

      <MyClassPlanCardSkeleton />

      <MyClassPlanCardSkeleton />
    </div>

  )

  const ReadyState = () => {

    const userName = auth?.user?.username as string
    return (
      <SwiperCarousel prevButtonClassName='prevClassPlanButton' nextButtonClassName='nextClassPlanButton'>
        {
          classPlans.map(el => (
            <MyClassPlanCard
              id={el.id}
              title={el.title ?? ''}
              userName={userName}
              dataTestId='cloe_super_library_my_class_plan'
            />
          ))
        }
      </SwiperCarousel>
    )
  }

  const stateRender = {
    [STATES.IDLE]: <></>,
    [STATES.LOADING]: <LoadingState />,
    [STATES.READY]: <ReadyState />,
    [STATES.ERROR]: <Alert content='Não foi possivel buscar seus planos de aula.' outlined={true} severity='error' actionButton={<ActionButton />} />
  }

  return (
    <section className={classes.homeSection}>
      <div className={classes.header}>
        <Text2 fontSize='lg' fontWeight='semibold' colorbrand='medium'>
          {t('Meus planos de aula')}
        </Text2>

        <Button
          endIcon={<AutoAwesomeOutlinedIcon />}
          variant='primary'
          className={classes.createClassPlanButton}
          onClick={handleCreateClassPlan}
          data-testid={'cloe_super_library_class_plan'}
        >
          {t('Criar')}
        </Button>
      </div>
      {
        stateRender[stateMachine]
      }
    </section>
  )
}
