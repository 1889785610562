import React, { ReactNode, useState } from 'react';
import 'swiper/swiper.min.css';
import { Box, IconButton, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useStyle from './style';

interface IProps {
  children: ReactNode;
  prevButtonClassName?: string;
  nextButtonClassName?: string;
}

export const SwiperCarousel: React.FC<IProps> = ({ children, prevButtonClassName, nextButtonClassName }) => {

  const [canNavigateLeft, setCanNavigateLeft] = useState(false)
  const [canNavigateRight, setCanNavigateRight] = useState(true)

  const classes = useStyle()

  const theme = useTheme()

  const onSlideChange = (swiper: any) => {
    setCanNavigateLeft(!swiper.isBeginning)
    setCanNavigateRight(!swiper.isEnd)
  };

  return (
    <Box
      className={`${classes.swiperContainer} ${classes.exploreCardsContainer}`}
      sx={{
        ...(canNavigateLeft && {
          '& .swiper::before': {
            [theme.breakpoints.up('sm')]: {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '70px',
              zIndex: 2,
              pointerEvents: 'none',
              left: 0,
              background: 'linear-gradient(90deg, #FFF 31.5%, rgba(255, 255, 255, 0.00) 100%)'
            }
          }
        }),
        ...(canNavigateRight && {
          '& .swiper::after': {
            [theme.breakpoints.up('sm')]: {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '70px',
              zIndex: 2,
              pointerEvents: 'none',
              right: 0,
              background: 'linear-gradient(270deg, #FFF 31.5%, rgba(255, 255, 255, 0.00) 100%)'
            }
          }
        })
      }}
    >
      <Swiper
        modules={[Navigation]}
        spaceBetween={16}
        slidesPerView={'auto'}
        className={classes.carouselContainer}
        loop={false}
        navigation={{
          nextEl: nextButtonClassName ? `.${nextButtonClassName}` : '.swiper-button-next',
          prevEl: prevButtonClassName ? `.${prevButtonClassName}` : '.swiper-button-prev',
        }}
        onSlideChange={onSlideChange}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            style={{
              flex: 0,
              scrollSnapAlign: 'start',
            }}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>

      <IconButton
        size='small'
        className={`${classes.prevButton} ${classes.navigationCarouselButttons} ${prevButtonClassName}`}
        sx={ canNavigateLeft ? { display: 'block' } : { display: 'none' }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        size='small'
        className={`${classes.nextButton} ${classes.navigationCarouselButttons} ${nextButtonClassName}`}
        sx={ canNavigateRight ? { display: 'block' } : { display: 'none' }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

SwiperCarousel.displayName = 'SwiperCarousel';
